$textColor: #adbec6;
$textUnimpColor: #536167;
$textErrColor: #ff5a5a;

$bgDarker: #293338;
$bgActiveColor: #252e33;
$bgErrColor: #d03939;

$borderUnimpColor: #35434c;
$borderActiveColor: #23d160;
$borderSuccColor: #196044;
$borderErrColor: #ff5a5a;
