.Toastify__toast--info,
.Toastify__toast--success,
.Toastify__toast--error,
.Toastify__toast--warning {
	background: #1e252f !important;
	border-radius: 10px !important;
}
.Toastify__progress-bar {
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
	border-radius: 11px 11px 0px 11px;
	height: 3px !important;
}
.Toastify__progress-bar--success {
	background-color: #00ff86 !important;
}
.Toastify__progress-bar--error {
	background-color: #ff4646 !important;
}
.Toastify__progress-bar--warning {
	background-color: #d3a11f !important;
}

.Toastify__progress-bar--info {
	background-color: #e2e2e2 !important;
}
