/* roboto-300 - latin_latin-ext */
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 300;
	src:
		local("Roboto Light"),
		local("Roboto-Light"),
		url("fonts/roboto-v20-latin_latin-ext-300.woff2") format("woff2");
}
/* roboto-regular - latin_latin-ext */
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 400;
	src:
		local("Roboto"),
		local("Roboto-Regular"),
		url("fonts/roboto-v20-latin_latin-ext-regular.woff2") format("woff2");
}
/* roboto-500 - latin_latin-ext */
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 500;
	src:
		local("Roboto Medium"),
		local("Roboto-Medium"),
		url("fonts/roboto-v20-latin_latin-ext-500.woff2") format("woff2");
	font-display: swap;
}
/* roboto-700 - latin_latin-ext */
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 700;
	src:
		local("Roboto Bold"),
		local("Roboto-Bold"),
		url("fonts/roboto-v20-latin_latin-ext-700.woff2") format("woff2");
}

/* roboto-mono-regular - latin */
@font-face {
	font-family: "Roboto Mono";
	font-style: normal;
	font-weight: 400;
	src:
		local("Roboto Mono"),
		local("RobotoMono-Regular"),
		url("fonts/roboto-mono-v7-latin-regular.woff2") format("woff2");
}

@font-face {
	font-family: "Barlow-BoldItalic";
	font-weight: "bold";
	font-style: "italic";
	src: url("fonts/barlow//Barlow-BoldItalic.woff2") format("woff2");
}

@font-face {
	font-family: "Barlow-Medium";
	font-weight: 500;
	src: url("fonts/barlow//Barlow-Medium.woff2") format("woff2");
}

@font-face {
	font-family: "Barlow-Bold";
	font-weight: normal;
	font-style: normal;
	src: url("fonts/barlow//Barlow-Bold.woff2") format("woff2");
}

@font-face {
	font-family: "Gamdom";
	src: url("fonts/gamdom/Gamdom-Regular.woff2") format("woff2");
	font-display: swap;
}

@font-face {
	font-family: "Gamdom";
	src: url("fonts/gamdom/Gamdom-Bold.woff2") format("woff2");
	font-weight: 700;
	font-display: swap;
}

@font-face {
	font-family: "Gamdom";
	src: url("fonts/gamdom/Gamdom-Medium.woff2") format("woff2");
	font-weight: 500;
	font-display: swap;
}

@font-face {
	font-family: "Gamdom";
	src: url("fonts/gamdom/Gamdom-Medium90.woff2") format("woff2");
	font-weight: 500;
	font-stretch: 90%;
	font-display: swap;
}

@font-face {
	font-family: "Gamdom";
	src: url("fonts/gamdom/Gamdom-Medium110.woff2") format("woff2");
	font-weight: 500;
	font-stretch: 110%;
	font-display: swap;
}
