@font-face {
	font-family: "icomoon";
	src: url("icomoon.woff2?5gvp9n") format("woff2");
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: "icomoon" !important;
	speak: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-telegram:before {
	content: "\e9b2";
}

.icon-settings:before {
	content: "\e900";
}

.icon-google:before {
	content: "\e9b1";
}

.icon-enlarge:before {
	content: "\e9b0";
}

.icon-hamburger-thin-filled:before {
	content: "\e9af";
}

.icon-hamburger-filled:before {
	content: "\e9ae";
}

.icon-gamdom-shield-filled:before {
	content: "\e9ad";
}

.icon-history-outlined:before {
	content: "\e9a8";
}

.icon-sort-amount-light-asc:before {
	content: "\e9aa";
}

.icon-sort-amount-light-desc:before {
	content: "\e9ac";
}

.icon-how-to-play-filled:before {
	content: "\e9a7";
}

.icon-joker-filled:before {
	content: "\e9a6";
}

.icon-lock-outlined:before {
	content: "\e9a1";
}

.icon-diamond-3:before {
	content: "\e9a0";
}

.icon-icon-emoji-outlined:before {
	content: "\e99d";
}

.icon-question-circle-outlined:before {
	content: "\e99e";
}

.icon-unmute-outlined:before {
	content: "\e99f";
}

.icon-mute-outlined:before {
	content: "\e9a2";
}

.icon-remove:before {
	content: "\e99c";
}

.icon-enlarge-filled:before {
	content: "\e9a3";
}

.icon-shrink-filled:before {
	content: "\e9a4";
}

.icon-remove21:before {
	content: "\ed3b";
}

.icon-eye-filled:before {
	content: "\e980";
}

.icon-crossed-eye-filled:before {
	content: "\e997";
}

.icon-steam-filled:before {
	content: "\e998";
}

.icon-gamdom-outlined:before {
	content: "\e99a";
}

.icon-dice:before {
	content: "\e995";
}

.icon-prov-fair-secure:before {
	content: "\e98d";
}

.icon-exclamation-mark-circle:before {
	content: "\e992";
}

.icon-multi-screens:before {
	content: "\e993";
}

.icon-copy1:before {
	content: "\e98b";
}

.icon-history:before {
	content: "\e98c";
}

.icon-change:before {
	content: "\e994";
}

.icon-wallet:before {
	content: "\e98a";
}

.icon-btc:before {
	content: "\e983";
	color: #f7931b;
}

.icon-eth .path1:before {
	content: "\e984";
	color: rgb(98, 126, 234);
}

.icon-eth .path2:before {
	content: "\e985";
	margin-left: -0.619140625em;
	color: rgb(118, 144, 245);
}

.icon-eth .path3:before {
	content: "\e986";
	margin-left: -0.619140625em;
	color: rgb(98, 126, 234);
}

.icon-eth .path4:before {
	content: "\e987";
	margin-left: -0.619140625em;
	color: rgb(118, 144, 245);
}

.icon-line:before {
	content: "\e988";
}

.icon-ltc:before {
	content: "\e989";
	color: #a4a4a4;
}

.icon-tether:before {
	content: "\e98e";
	color: #1eb688;
}

.icon-twitter1:before {
	content: "\e991";
	color: #1da1f2;
}

.icon-unlink:before {
	content: "\e982";
	color: #68757b;
}

.icon-checkmarked-user:before {
	content: "\e981";
}

.icon-trophy:before {
	content: "\e905";
}

.icon-cogs:before {
	content: "\e907";
}

.icon-777:before {
	content: "\e904";
}

.icon-hand-holding-usd:before {
	content: "\e908";
}

.icon-moneyfalling:before {
	content: "\e90a";
}

.icon-wallet1:before {
	content: "\e90c";
}

.icon-sports-field:before {
	content: "\e90e";
}

.icon-sword:before {
	content: "\e911";
}

.icon-discord-icon:before {
	content: "\e915";
}

.icon-reddit-icon:before {
	content: "\e917";
}

.icon-telegram-icon:before {
	content: "\e919";
}

.icon-youtube-icon:before {
	content: "\e91b";
}

.icon-items-expand:before {
	content: "\e91d";
}

.icon-crash:before {
	content: "\e91f";
}

.icon-hilo:before {
	content: "\e923";
}

.icon-roulette:before {
	content: "\e926";
}

.icon-tradeup:before {
	content: "\e928";
}

.icon-copy2:before {
	content: "\e956";
}

.icon-coin-dollar:before {
	content: "\e966";
}

.icon-pushpin:before {
	content: "\e972";
}

.icon-clock2:before {
	content: "\e97c";
}

.icon-stopwatch:before {
	content: "\e97f";
}

.icon-spinner2:before {
	content: "\e98f";
}

.icon-spinner22:before {
	content: "\e990";
}

.icon-spinner11:before {
	content: "\e999";
}

.icon-search2:before {
	content: "\e99b";
}

.icon-unlocked:before {
	content: "\e9a5";
}

.icon-cog2:before {
	content: "\e9a9";
}

.icon-hammer:before {
	content: "\e9ab";
}

.icon-cloud-upload2:before {
	content: "\e9d8";
}

.icon-upload2:before {
	content: "\e9db";
}

.icon-eye-blocked:before {
	content: "\e9e6";
}

.icon-star-full:before {
	content: "\e9ee";
}

.icon-plus2:before {
	content: "\ea1f";
}

.icon-cross:before {
	content: "\ea24";
}

.icon-checkmark:before {
	content: "\ea25";
}

.icon-facebook2:before {
	content: "\eaa5";
}

.icon-youtube3:before {
	content: "\eab2";
}

.icon-game-help:before {
	content: "\eb0c";
}

.icon-refresh2:before {
	content: "\eb0d";
}

.icon-volume-on:before {
	content: "\eb0f";
}

.icon-diamond2:before {
	content: "\eb10";
}

.icon-emoji:before {
	content: "\eb11";
}

.icon-volume-off2:before {
	content: "\eb19";
}

.icon-history3:before {
	content: "\eb1a";
}

.icon-error:before {
	content: "\eb1d";
}

.icon-money-bag:before {
	content: "\eb1e";
}

.icon-success:before {
	content: "\eb1f";
}

.icon-joker:before {
	content: "\eb22";
}

.icon-deposit:before {
	content: "\eb23";
}

.icon-unhappy-emote:before {
	content: "\eb35";
}

.icon-meh-emote:before {
	content: "\eb36";
}

.icon-happy-emote:before {
	content: "\eb37";
}

.icon-cancel-circle1:before {
	content: "\ec3d";
}

.icon-arrow-down221:before {
	content: "\ec6e";
}

.icon-arrow-left221:before {
	content: "\ec70";
}

.icon-steam21:before {
	content: "\ecdc";
}

.icon-players:before {
	content: "\ed2e";
}

.icon-users:before {
	content: "\ed3f";
}

.icon-news-icon:before {
	content: "\ed40";
}

.icon-education-icon:before {
	content: "\ed42";
}

.icon-ARROW-righty:before {
	content: "\ed43";
}

.icon-bonus-bal:before {
	content: "\ed45";
}

.icon-home:before {
	content: "\ed48";
}

.icon-search:before {
	content: "\f002";
}

.icon-heart:before {
	content: "\f004";
}

.icon-user:before {
	content: "\f007";
}

.icon-check:before {
	content: "\f00c";
}

.icon-close:before {
	content: "\f00d";
}

.icon-cog:before {
	content: "\f013";
}

.icon-refresh:before {
	content: "\f021";
}

.icon-lock:before {
	content: "\f023";
}

.icon-qrcode:before {
	content: "\f029";
}

.icon-check-square-o:before {
	content: "\f046";
}

.icon-chevron-left:before {
	content: "\f053";
}

.icon-chevron-right:before {
	content: "\f054";
}

.icon-question-circle:before {
	content: "\f059";
}

.icon-info-circle:before {
	content: "\f05a";
}

.icon-arrow-left:before {
	content: "\f060";
}

.icon-arrow-right:before {
	content: "\f061";
}

.icon-arrow-down:before {
	content: "\f063";
}

.icon-plus:before {
	content: "\f067";
}

.icon-minus:before {
	content: "\f068";
}

.icon-exclamation-circle:before {
	content: "\f06a";
}

.icon-gift:before {
	content: "\f06b";
}

.icon-eye:before {
	content: "\f06e";
}

.icon-exclamation-triangle:before {
	content: "\f071";
}

.icon-calendar:before {
	content: "\f073";
}

.icon-chevron-up:before {
	content: "\f077";
}

.icon-chevron-down:before {
	content: "\f078";
}

.icon-twitter-square:before {
	content: "\f081";
}

.icon-facebook-square:before {
	content: "\f082";
}

.icon-heart-o:before {
	content: "\f08a";
}

.icon-phone:before {
	content: "\f095";
}

.icon-square-o:before {
	content: "\f096";
}

.icon-twitter:before {
	content: "\f099";
}

.icon-unlock:before {
	content: "\f09c";
}

.icon-bullhorn:before {
	content: "\f0a1";
}

.icon-bell-o:before {
	content: "\f0a2";
}

.icon-copy:before {
	content: "\f0c5";
}

.icon-envelope:before {
	content: "\f0e0";
}

.icon-exchange:before {
	content: "\f0ec";
}

.icon-angle-double-left:before {
	content: "\f100";
}

.icon-angle-double-right:before {
	content: "\f101";
}

.icon-angle-left:before {
	content: "\f104";
}

.icon-angle-right:before {
	content: "\f105";
}

.icon-angle-up:before {
	content: "\f106";
}

.icon-angle-down:before {
	content: "\f107";
}

.icon-info:before {
	content: "\f129";
}

.icon-dollar:before {
	content: "\f155";
}

.icon-bitcoin:before {
	content: "\f15a";
}

.icon-sort-amount-asc:before {
	content: "\f160";
}

.icon-sort-amount-desc:before {
	content: "\f161";
}

.icon-thumbs-up:before {
	content: "\f164";
}

.icon-instagram:before {
	content: "\f16d";
}

.icon-male:before {
	content: "\f183";
}

.icon-vk:before {
	content: "\f189";
}

.icon-child:before {
	content: "\f1ae";
}

.icon-steam:before {
	content: "\f1b6";
}

.icon-paper-plane:before {
	content: "\f1d8";
}

.icon-paper-plane-o:before {
	content: "\f1d9";
}

.icon-twitch:before {
	content: "\f1e8";
}

.icon-line-chart:before {
	content: "\f201";
}

.icon-handshake-o:before {
	content: "\f2b5";
}

.icon-user-circle-o:before {
	content: "\f2be";
}

.icon-User:before {
	content: "\e901";
}

.icon-User1:before {
	content: "\e902";
}

.icon-Activity:before {
	content: "\e903";
}

.icon-Add-User:before {
	content: "\e906";
}

.icon-Arrow---Down-2:before {
	content: "\e909";
}

.icon-Arrow---Down-3:before {
	content: "\e90b";
}

.icon-Arrow---Down-Circle:before {
	content: "\e90d";
}

.icon-Arrow---Down-Square:before {
	content: "\e90f";
}

.icon-Arrow---Down:before {
	content: "\e910";
}

.icon-Arrow---Left-2:before {
	content: "\e912";
}

.icon-Arrow---Left-3:before {
	content: "\e913";
}

.icon-Arrow---Left-Circle:before {
	content: "\e914";
}

.icon-Arrow---Left-Square:before {
	content: "\e916";
}

.icon-Arrow---Left:before {
	content: "\e918";
}

.icon-Arrow---Right-2:before {
	content: "\e91a";
}

.icon-Arrow---Right-3:before {
	content: "\e91c";
}

.icon-Arrow---Right-Circle:before {
	content: "\e91e";
}

.icon-Arrow---Right-Square:before {
	content: "\e920";
}

.icon-Arrow---Right:before {
	content: "\e921";
}

.icon-Arrow---Up-2:before {
	content: "\e922";
}

.icon-Arrow---Up-3:before {
	content: "\e924";
}

.icon-Arrow---Up-Circle:before {
	content: "\e925";
}

.icon-Arrow---Up-Square:before {
	content: "\e927";
}

.icon-Arrow---Up:before {
	content: "\e929";
}

.icon-arrows:before {
	content: "\e92a";
}

.icon-Bag-2:before {
	content: "\e92b";
}

.icon-Bag:before {
	content: "\e92c";
}

.icon-Bookmark:before {
	content: "\e92d";
}

.icon-Buy:before {
	content: "\e92e";
}

.icon-Calendar:before {
	content: "\e92f";
}

.icon-Call-Missed:before {
	content: "\e930";
}

.icon-Call-Silent:before {
	content: "\e931";
}

.icon-Call:before {
	content: "\e932";
}

.icon-Calling:before {
	content: "\e933";
}

.icon-Camera:before {
	content: "\e934";
}

.icon-Category:before {
	content: "\e935";
}

.icon-Close-Square:before {
	content: "\e936";
}

.icon-connect:before {
	content: "\e937";
}

.icon-Danger-Circle:before {
	content: "\e938";
}

.icon-Danger-Triangle:before {
	content: "\e939";
}

.icon-Delete:before {
	content: "\e93a";
}

.icon-Discount:before {
	content: "\e93b";
}

.icon-Discovery:before {
	content: "\e93c";
}

.icon-Document:before {
	content: "\e93d";
}

.icon-Download:before {
	content: "\e93e";
}

.icon-Edit-Square:before {
	content: "\e93f";
}

.icon-Edit:before {
	content: "\e940";
}

.icon-Filter-2:before {
	content: "\e941";
}

.icon-Filter:before {
	content: "\e942";
}

.icon-Folder:before {
	content: "\e943";
}

.icon-Game:before {
	content: "\e944";
}

.icon-Graph:before {
	content: "\e945";
}

.icon-Heart-basic:before {
	content: "\e946";
}

.icon-Heart:before {
	content: "\e947";
}

.icon-Hide:before {
	content: "\e948";
}

.icon-Home:before {
	content: "\e949";
}

.icon-Chart:before {
	content: "\e94a";
}

.icon-Chat:before {
	content: "\e94b";
}

.icon-Image-2:before {
	content: "\e94c";
}

.icon-Image:before {
	content: "\e94d";
}

.icon-Info-Square:before {
	content: "\e94e";
}

.icon-Location:before {
	content: "\e94f";
}

.icon-Lock:before {
	content: "\e950";
}

.icon-Login:before {
	content: "\e951";
}

.icon-Logout:before {
	content: "\e952";
}

.icon-Message:before {
	content: "\e953";
}

.icon-More-Circle:before {
	content: "\e954";
}

.icon-More-Square:before {
	content: "\e955";
}

.icon-Notification:before {
	content: "\e957";
}

.icon-Paper-Download:before {
	content: "\e958";
}

.icon-Paper-Fail:before {
	content: "\e959";
}

.icon-Paper-Negative:before {
	content: "\e95a";
}

.icon-Paper-Plus:before {
	content: "\e95b";
}

.icon-Paper-Upload:before {
	content: "\e95c";
}

.icon-Paper:before {
	content: "\e95d";
}

.icon-Password:before {
	content: "\e95e";
}

.icon-Play:before {
	content: "\e95f";
}

.icon-Plus:before {
	content: "\e960";
}

.icon-Profile:before {
	content: "\e961";
}

.icon-Scan:before {
	content: "\e962";
}

.icon-screw:before {
	content: "\e963";
}

.icon-Search:before {
	content: "\e964";
}

.icon-Send:before {
	content: "\e965";
}

.icon-Setting:before {
	content: "\e967";
}

.icon-Shield-Done:before {
	content: "\e968";
}

.icon-Shield-Fail:before {
	content: "\e969";
}

.icon-Show:before {
	content: "\e96a";
}

.icon-smile:before {
	content: "\e96b";
}

.icon-Star:before {
	content: "\e96c";
}

.icon-Swap:before {
	content: "\e96d";
}

.icon-Tick-Square:before {
	content: "\e96e";
}

.icon-Ticket-Star:before {
	content: "\e96f";
}

.icon-Ticket:before {
	content: "\e970";
}

.icon-Time-Circle:before {
	content: "\e971";
}

.icon-Time-Square:before {
	content: "\e973";
}

.icon-Unlock:before {
	content: "\e974";
}

.icon-Upload:before {
	content: "\e975";
}

.icon-verify:before {
	content: "\e976";
}

.icon-Video:before {
	content: "\e977";
}

.icon-Voice-2:before {
	content: "\e978";
}

.icon-Voice:before {
	content: "\e979";
}

.icon-Volume-Down:before {
	content: "\e97a";
}

.icon-Volume-Off:before {
	content: "\e97b";
}

.icon-Volume-Up:before {
	content: "\e97d";
}

.icon-Wallet:before {
	content: "\e97e";
}

.icon-Work:before {
	content: "\e996";
}

.icon-gammdom-symbol:before {
	content: "\e9b3";
}
