@keyframes pulse-red {
	0% {
		box-shadow: 0 0 0 0 rgba(189, 59, 59, 0.7);
	}

	70% {
		box-shadow: 0 0 0 10px rgba(189, 59, 59, 0);
	}

	100% {
		box-shadow: 0 0 0 0 rgba(189, 59, 59, 0);
	}
}

.slots-battles-tooltip {
	.rc-tooltip-inner {
		background: #12191d;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
		border-radius: 6px;
		font-weight: 500;
		font-size: 12px;
		color: #a7b5bc;
	}

	&.rc-tooltip-placement-left .rc-tooltip-arrow {
		@include posVertAlign;

		border-width: 10px;
		right: -20px;
		border-left-color: #12191d;
	}

	&.rc-tooltip-placement-top .rc-tooltip-arrow {
		@include posHorAlign;

		border-width: 10px;
		bottom: -20px;
		border-top-color: #12191d;
	}
}
