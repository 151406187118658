.loading-container {
	margin: 0 auto;
	margin-top: 20%;
}
.table_titles .pl_name {
	width: 16%;
	display: inline-block;
	text-align: right;
	font-size: 14px;
	line-height: 1.142857142857143;
	padding-left: 20px;
	text-transform: uppercase;
}

.table_titles .pl_name:first-child {
	width: 51%;
	text-align: left;
	padding-left: 0;
}

.field_group input {
	background: transparent;
}

.field_group.resetPassCaptcha .g-recaptcha {
	display: inline-block;
}

.bet_btn {
	color: #000;
}

.graph-container {
	display: flex;
	justify-content: center;
}

txt_white {
	color: #fff;
}

.inline_block {
	display: inline-block;
}

.chat_rooms.chat_lang_show .chat_langs {
	transform: translateX(0);
	opacity: 1;
	visibility: visible;
}

.tradeup_top_bar {
	display: inline-block;
	vertical-align: middle;
}

.bet_btn_hilo {
	width: 100%;
}

.icon-lo-arrow:before {
	content: "\f0d7";
}

.icon-hi-arrow:before {
	content: "\f0d8";
}

.counting {
	width: 27px;
	justify-content: space-between;
	align-items: center;
	display: flex;
}

.game_filter {
	text-align: center;
	padding-bottom: 20px;
}

.game_filter .method {
	cursor: pointer;
	display: inline-block;
	cursor: pointer;
	padding: 4px;
	margin: 4px;
	border: 2px solid transparent;
}

.game_filter .method.active {
	cursor: default;
	background-color: transparent;
	border-radius: 5px;
	border: 2px solid #607d8b;
}

.game_filter .method .icon {
	width: auto;
	height: 30px;
}

.live-drop-animation-enter {
	opacity: 0.01;
	transform: rotateX(-90deg) rotateY(-45deg) translateY(-30px);
	transition: all 1.5s cubic-bezier(0.36, -0.64, 0.34, 1.76);
}

.live-drop-animation-enter-active {
	opacity: 1;
	transform: none;
}

.rc-tooltip-inner {
	border-radius: 8px;
	background: linear-gradient(44.72deg, #2f3e45 0%, #3d5059 100%);
	box-shadow: 0 0 10px 0 #141a1d;
}

.side_title {
	display: block;
}

.rainDropdown {
	font-size: 0;
	margin-left: 0;
	opacity: 0;
	transition: opacity 0.5s;
}

.rainDropdown .tipRain {
	font-size: 14px;
	padding: 5px 15px;
	background-color: #607d8b;
	color: #fff;
	border-radius: 6px;
	cursor: pointer;
	line-height: 1;
	position: absolute;
	right: -56px;
	top: 50%;
	transform: translateY(-50%);
	transition: background-color 0.5s;
}

.rainDropdown .tipRain::before {
	content: "";
	width: 0;
	height: 0;
	border-top: 5px solid transparent;
	border-bottom: 5px solid transparent;
	border-right: 10px solid #607d8b;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 100%;
	transition: border-right 0.5s;
}

.rainDropdown .tipRain:hover {
	background-color: #3d4f59;
}

.rainDropdown .tipRain:hover::before {
	border-right-color: #3d4f59;
}

.chat_user:hover .rainDropdown {
	opacity: 1;
}

.rc-tooltip {
	position: absolute;
	z-index: 1070;
	display: block;
	visibility: visible;
	font-size: 12px;
	line-height: 1.5;
	opacity: 0.9;
}

.rc-tooltip-placement-bottom,
.rc-tooltip-placement-bottomLeft,
.rc-tooltip-placement-bottomRight {
	padding: 9px 0 5px 0;
}

.rc-tooltip.tradeup-tooltip {
	opacity: 1;
}

.rc-tooltip-arrow {
	position: absolute;
	width: 0;
	height: 0;
	border-color: transparent;
	border-style: solid;
}

.rc-tooltip-placement-bottom .rc-tooltip-arrow,
.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow,
.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
	top: 4px;
	margin-left: -5px;
	border-width: 0 5px 5px;
	border-bottom-color: #373737;
}

.rc-tooltip-placement-bottom .rc-tooltip-arrow {
	left: 50%;
}

.rc-tooltip.tradeup-tooltip.rc-tooltip-placement-bottom .rc-tooltip-arrow {
	border-bottom-color: #3d5059;
}

.rc-tooltip-inner {
	padding: 8px 10px;
	color: #fff;
	text-align: left;
	text-decoration: none;
	background-color: #373737;
	border-radius: 6px;
	box-shadow: 0 0 4px rgba(0, 0, 0, 0.17);
	min-height: 34px;
}

.tradeup-info-extended,
.rc-tooltip.tradeup-tooltip .rc-tooltip-inner {
	text-align: center;
	box-shadow: 3px 3px 15px 0px #000000;
	background-color: rgba(27, 27, 33, 0.98);
	width: 200px;
	padding: 1ex;
}

.ReactVirtualized__Grid:focus {
	outline: none;
}
