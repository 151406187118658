.gameinfo-modal-content .modal-close-button.title {
	position: absolute;
	left: 10px;
	top: 10px;
	line-height: 1ex;
	font-size: 200%;
	z-index: 1;
	width: 20px;
	height: 20px;
	margin-top: 2px;
}

.chat_lan_li.chat_lan_disable .chat_lan,
.chat_lan_li.chat_disable .chat_lan {
	color: #607d8b;
	pointer-events: none;
}

.chat_lan_li.chat_lan_disable .lan_close {
	display: none;
}

.chat_lan_li .lan_close {
	display: none;
}

.chat_disable .lan_close {
	display: block;
}

.logout {
	cursor: pointer;
}

#logoutDropdown {
	display: none;
	position: absolute;
	cursor: pointer;
	right: 0;
	top: 100%;
	width: 100%;
	border-bottom-left-radius: 7px;
	border-bottom-right-radius: 7px;
	background-color: #2f3e45;
	color: #fff;
	font-size: 14px;
	padding: 20px 0 15px;
	line-height: 1;
	transition: color 0.4s;
}

#logoutDropdown:hover {
	color: #0ee07b;
}

.help_menu {
	display: inline-block;
}

.info_overlay .ReactModal__Content > div {
	text-align: left;
}

.info_overlay .description {
	margin-left: 20px;
	margin-top: 20px;
}

.helper_text a {
	color: #adbec6;
}

.timing:hover,
.timing.tab-active {
	color: #0ee07b;
}

.timing:hover:before,
.timing.tab-active:before {
	color: #607d8b;
}

.btn[disabled] {
	background-color: #41535d;
	color: #1b2428;
	pointer-events: none;
}

.txtcenter {
	text-align: center;
}

.no_results_icon {
	font-size: 75px;
	display: inline-block;
	color: #d4d8d9;
}

.no_results_text {
	color: #d4d8d9;
	font-size: 16px;
	line-height: 1.3125;
	margin-top: 15px;
}

.notdisplay {
	display: none;
}

.search_criteria {
	margin-bottom: 15px;
}

.search_for_text_team,
.search_text_team {
	display: inline-block;
	vertical-align: middle;
}

.search_for_text_team {
	color: #0ee07b;
	font-size: 20px;
	line-height: 1.2;
}

.search_text_team {
	color: #d4d8d9;
	font-size: 16px;
	line-height: 1.3125;
	margin-left: 5px;
}

.bet_btn[disabled] {
	pointer-events: none;
}

.balloon {
	color: #ffffff;
}

.amcharts-balloon-div > div {
	text-align: left !important;
}

.amcharts-chart-div > a {
	display: none !important;
}

.question_mark {
	width: 26px;
	height: 26px;
	font-size: 20px;
	background-color: #adbec6;
	color: #0b0b0d;
	border-radius: 50%;
	position: relative;
	margin-bottom: 10px;
	display: inline-block !important;
}

.question_mark:before {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
