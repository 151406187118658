.inp-grp {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	&--with-spacing {
		justify-content: space-around;
	}
}
.inp-grp-title {
	color: $textColor;
	margin-bottom: 10px;
	flex-basis: 100%;

	&--unimp {
		color: $textUnimpColor;
	}
	&--italic {
		font-style: italic;
	}
	&--margin-top {
		padding-top: 15px;
	}
	&--margin-left {
		padding-left: 15px;
	}
}

.inp-grp-subtitle {
	margin-bottom: 15px;
	color: $textUnimpColor;

	@at-root .inp-grp-title + & {
		margin-top: -5px;
	}
}

.inp-wrap {
	flex-wrap: wrap;
	background: $bgDarker !important;
	position: relative;
	width: auto;
	cursor: text;
	display: flex;
	align-items: center;
	border-radius: 6px;
	height: 50px;

	&--new {
		color: white;
		border-width: 2px;

		.inp::placeholder {
			color: white;
		}
	}

	&:last-child {
		margin-right: 0 !important;
	}

	&--name {
		flex-grow: 1;
	}

	&--title {
		width: 110px;
	}
	&--dropdown {
		border: 2px solid #35434c;
		padding: 15px;
		cursor: pointer;
	}

	&--month,
	&--year {
		width: 160px;
	}
	&--day {
		width: 110px;
	}

	&--half {
		width: calc(50% - 5px);

		&:nth-child(even) {
			margin-right: 0 !important;
		}
	}
	&--full {
		width: 100%;
		margin-right: 0 !important;
	}

	&--success .inp-fieldset {
		border-color: $borderSuccColor;
	}
	&--error .inp-fieldset {
		border-color: $borderErrColor;
	}
	&--active {
		background: $bgActiveColor !important;
		.inp-fieldset {
			border-color: $borderActiveColor !important;
		}
	}
	&--disabled {
		cursor: not-allowed;
		opacity: 0.6;
		input {
			cursor: not-allowed;
			opacity: 0.6;
		}
	}
	&--light {
		.inp-fieldset {
			border: none;
		}

		background-color: #e8e8e8;
		opacity: 1;

		i {
			color: rgba(38, 47, 52, 0.75);
		}
	}
}

.inp-fieldset {
	height: 100%;
	border: 2px solid #293338;
	width: 100%;
	border-radius: 6px;
	position: absolute;
	top: 0;
	left: 0;
	padding: inherit;

	&--selectable {
		pointer-events: none;
	}
}

.inp-title-legend {
	display: block;
	width: auto;
	max-width: 0.01px;
	transition:
		max-width 0.3s,
		padding 0.3s;
	max-width: 0;
	visibility: hidden;
	height: 4px; // if you see a small border on floated label increase height;
}

.inp-title-legend-span {
	display: inline-block;
	padding: 0 5px;
}

.inp-title {
	color: white;
	position: absolute;
	transition:
		top 0.3s,
		font-size 0.3s,
		padding 0.3s,
		left 0.3s;
	width: 100%;
	left: 0;
	padding: inherit;
	font-size: 14px;
	@include posVertAlign;

	@at-root .inp-wrap--up {
		.inp-title {
			top: 1px;
			left: 7px; // padding-left of .inp-title-legend-span + border-width of .inp-fieldset
		}
		.inp-title,
		.inp-title-legend {
			font-size: 12px;
		}
		.inp-title-legend {
			max-width: 1000px;
		}
	}

	@at-root .inp-wrap--error & {
		color: $borderErrColor;
	}

	&--light {
		color: rgba(38, 47, 52, 0.75);
	}
	@at-root .inp-wrap--success,
		.inp-wrap--active {
		.inp-title,
		.inp-icon:not(.inp-icon--right) {
			// font-size: 13px;
			color: $gamdomColor !important;
		}
	}

	@at-root .dropdown-wrap--open > & {
		color: $textColor;
	}
	.dropdown-icon {
		color: #adbec6;
		font-size: 14px;
	}
}

.inp-icon {
	margin-right: 10px;
	font-size: 15px;
	color: $textUnimpColor;

	&--right {
		font-size: 18px;
		margin-left: 5px;
		margin-right: 0;

		&:hover {
			color: $gamdomColor;
		}
	}

	&--active {
		color: $gamdomColor;
	}
}

.inp-inner {
	height: 100%;
	display: inline-flex;
	width: 100%;
	align-items: center;
	position: relative;
}

.inp-inner {
	padding: 0 10px 0 15px;
}

.inp {
	width: 100%;
	color: white;
	background: transparent;
	height: 19px;
	// bottom: -7px;
	position: relative;
	cursor: pointer;

	&::placeholder {
		color: #536167;
	}

	&--no-title {
		bottom: unset;
	}
	&:-webkit-autofill {
		transition-delay: 9999s;
		transition-property: background-color, color;
	}
}

.inp-currency-badge {
	border-radius: 4px;
	width: 50px;
	height: 30px;
	text-align: center;
	display: inline-block;
	padding: 4px 8px;
	font-size: 14px;
	font-weight: bold;
	z-index: 1;
	img {
		height: 100%;
		width: 100%;
	}
}
.inp-clear-btn {
	background: #afc2cb;
	color: #232c31;
	cursor: pointer;
	border-radius: 4px;
	margin-left: 10px;
	text-align: center;
	display: inline-block;
	width: auto;
	padding: 4px 12px;
	font-size: 14px;
	font-weight: normal;
	z-index: 1;
	transition: 333ms;
}
.inp-clear-btn:hover {
	background: #d2e8f2;
}
/** 
* Just play with the height invisible field of fieldset and it's position if you see glitches with the border
*/
.inp-msg {
	font-size: 14px;
	margin-top: 4px;
	line-height: 19px;
	display: flex;
	color: $textErrColor;

	.inp-err-msg-i {
		font-size: 16px;
		margin-right: 3px;
	}
}
