.eg {
	&__win {
		cursor: pointer;
		margin-bottom: 10px;
		background: #2f3d45;
		border-radius: 6px;
		width: 100%;
		padding: 15px;
		min-height: 70px;
		transition: background 0.2s linear;
		display: flex;
		filter: drop-shadow(0 2px 0 #1e282c);
		align-items: center;

		&:hover {
			background: #3b505c;
		}
	}
	&__win-img {
		width: 100px;
		height: 100%;
		margin-right: 15px;
		background-size: 100% 100%;
		background-repeat: no-repeat;
		border-radius: 6px;
		min-height: inherit;
	}
	&__win-top-row {
		margin-bottom: 6px;
	}
	&__win-username {
		font-weight: 500;
		color: white;
	}
	&__win-amount {
		color: #23d160;
		font-weight: 500;
	}
	&__win-playing {
		font-size: 12px;
		vertical-align: middle;
		color: rgba(174, 191, 199, 0.5);
	}
	&__win-game {
		font-weight: 500;
		vertical-align: middle;
	}
	&__win-play {
		opacity: 0;
		margin-left: auto;
		border-radius: 6px;
		color: white;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 87px;
		height: 40px;
		background: #4d6776;
		filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.15));
		transition: opacity 0.2s linear;

		@at-root .eg__win:hover & {
			opacity: 1;
		}
	}
}
