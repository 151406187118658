.liked-icon {
	color: #23d160;
}

.tournament-modal {
	max-width: 514px !important;
	margin-left: auto;
	margin-right: auto;
	height: auto !important;

	@media screen and (max-height: 767px) and (max-width: 600px) {
		margin: 10vh auto 0px auto;
	}
	@media screen and (max-height: 600px) and (max-width: 600px) {
		margin: 30vh auto 0px auto;
	}
}

@media (max-width: 1650px) {
	.eg__game {
		width: 22%;
		margin-right: 4%;

		&:nth-child(5n) {
			margin-right: 4%;
		}
		&:nth-child(4n) {
			margin-right: 0;
		}
	}
}

@media (max-width: 1400px) {
	.eg__filters {
		flex-direction: column;
	}
	.eg__filter {
		width: 100%;
	}
	.eg__search {
		max-width: unset;
	}
}

@media (max-width: 1024px) {
	.eg {
		padding: 30px;
	}
	.eg__game {
		width: 31%;
		margin-right: 3.5%;

		&:nth-child(5n) {
			margin-right: 3.5%;
		}
		&:nth-child(4n) {
			margin-right: 3.5%;
		}
		&:nth-child(3n) {
			margin-right: 0;
		}
	}

	.eg__last-wins {
		width: 100%;
		padding: 0;
	}
}

@media (max-width: 700px) {
	.eg__filters-left {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		.eg__filter {
			width: 31%;
			flex-wrap: wrap;
			margin-right: 0;
			max-width: unset;
		}
	}
	.eg__filter {
		font-size: 12px;
		::placeholder {
			font-size: 12px;
		}
	}
}

@media (max-width: 600px) {
	.eg__back {
		position: unset;
	}
	.eg__game-area-title {
		display: block;
		margin-top: 10px;
	}
}

@media (max-width: 500px) {
	.eg__game {
		width: 100%;
	}
}
