.dropdown {
	margin-top: 5px;
	padding-top: 10px;
	border-radius: 6px;
	background-color: #141a1d;
	width: 100%;
	z-index: 1;
	height: 0;
	display: none;
	position: absolute;
	left: -2px;
	top: 100%;
	padding-right: 5px;
	padding-left: 5px;
	padding-bottom: 10px;
	max-height: 300px;

	&__img {
		max-height: 22px;
		max-width: 22px;
		margin-right: 10px;
	}

	&__search-wrap {
		height: 38px;
		padding: 0 5px;
		margin-bottom: 10px;
	}

	&__search {
		background: #222c31 !important;
		height: 100%;

		.inp-fieldset {
			border: unset;
		}
	}

	&__opts-wrap {
		overflow-y: scroll;
		overflow-x: hidden;
		max-height: 215px;
	}

	&__subtext {
		color: #606c72;
		font-size: 10px;
		margin-left: 2px;
		line-height: initial;
		vertical-align: baseline;
	}

	@at-root .dropdown-wrap--open > & {
		display: block;
		height: auto;
		max-height: 280px;
	}
}

.dropdown-wrap {
	&--open {
		// apply open styles here
	}
}

.inp-wrap--dropdown {
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Old versions of Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none;

	&.inp-wrap--success {
		border-color: #23d160 !important;
		color: initial;

		.inp-title {
			color: white !important;
		}
	}

	.inp-title {
		position: static;
		padding: 0;
		transform: unset;
		display: flex;
		align-items: center;
	}
}

.dropdown-icon {
	position: absolute;
	right: 10px;
	font-weight: 700;
	font-size: 16px;
	@include posVertAlign;
}

.option {
	color: #aebfc7;
	padding-left: 20px;
	line-height: 22px;
	margin-bottom: 10px;
	display: flex;
	align-items: center;

	&:hover {
		color: $textColor;
	}
}

@media (max-width: 450px) {
	.option {
		padding-left: 10px;
	}
}
