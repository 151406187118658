body,
input,
textarea,
select,
button {
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
}

* {
	outline: none;
}

img {
	max-width: 100%;
	height: auto;
}

.text_center {
	text-align: center;
}

.text_left {
	text-align: left;
}

.text_right {
	text-align: right;
}

.bbb {
	border-radius: 8px;
	background: linear-gradient(44.72deg, #2f3e45 0%, #3d5059 100%);
	box-shadow: 0 0 10px 0 #141a1d;
}

select::-ms-expand {
	display: none;
}

select {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

input[type="text"]::-ms-clear {
	display: none;
}

input {
	background-color: transparent;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type="number"] {
	-moz-appearance: textfield;
}

/* == TYPOGRAPHY == */

h1,
.h1 {
	font-size: 36px;
	font-weight: 300;
}

h2,
.h2 {
	font-size: 30px;
	font-weight: 500;
}

h3,
.h3 {
	font-size: 24px;
	font-weight: 500;
}

h4,
.h4 {
	font-size: 20px;
}

h5,
.h5 {
	font-size: 20px;
	font-weight: 300;
}

h6,
.h6 {
	font-size: 18px;
}

.editor ul li,
.editor ol li {
	display: block;
	font-size: 16px;
	position: relative;
	padding-left: 10px;
	line-height: 1.3125;
}

.editor ol li {
	padding-left: 20px;
	counter-increment: item;
}

.editor ul li:before {
	position: absolute;
	left: 0;
	top: 7px;
	content: "";
	width: 4px;
	height: 4px;
	border-radius: 50%;
	background-color: #d4d8d9;
}

.editor ol li:before {
	position: absolute;
	left: 0;
	top: 0px;
	content: counter(item) ".";
}

.editor a {
	color: #0ee07b;
}

.editor a:hover {
	color: #0a7843;
}

.editor i,
.editor em {
	font-style: italic;
}

/* == TYPOGRAPHY == */

/* == HEADER == */

.logo {
	display: inline-block;
	vertical-align: middle;
}

.player_count {
	font-size: 20px;
	line-height: 1.2;
	padding-left: 5px;
}

.menu_section {
	display: inline-block;
	vertical-align: middle;
}

.menu_section {
	width: 76.55%;
	text-align: right;
}

.menus {
	vertical-align: middle;
	position: relative;
	overflow: hidden;
	height: 100%;
	flex-grow: 1;
}

.question_mark {
	transition: all 0.4s ease !important;
}

.help_menu,
.settings_menu {
	display: inline-block;
	margin-left: 15px;
}

.menu_flag {
	padding: 0;
	vertical-align: middle;
	position: relative;
	cursor: pointer;
}

.menu_flag:focus {
	outline: none;
}

.flag_m {
	color: #ffffff;
	font-size: 13px;
	line-height: normal;
	cursor: pointer;
	position: relative;
	padding: 6px 25px 6px 8px;
	background: transparent;
	z-index: 1;
	width: 100%;
}

.flag_m > img {
	border: 1px solid transparent;
	transition: border 0.4s;
	padding: 1px;
	width: auto;
	height: 21px;
	box-sizing: content-box;
}

.flag_m:hover img,
.flags_show .flag_m img {
	border-color: #10de7c;
}

.flag_link {
	display: block;
	margin-top: 12px;
	text-transform: none;
}

.flag_link:after {
	clear: both;
	display: block;
	content: "";
}

.flag_link:first-child {
	margin-top: 0;
}

.flag_img {
	float: right;
	width: 18px;
	height: 12px;
	margin-top: 2px;
}

.flag_lang {
	font-size: 12px;
	color: #000;
	transition: color 0.4s;
}

.flag_link:hover .flag_lang {
	color: #0ee07b;
}

/* == HEADER == */

.site_content {
	left: 0;
	top: 0;
	width: 100%;
}

#game-inner-container {
	height: 100%;
}

.mark_chat_expand {
	transform: translateX(-100%);
}

/* == CHAT ROOM SECTION == */

.chat_rooms {
	position: relative;
	height: 100%;
	z-index: 1;
}

.chat_rooms_head {
	padding: 0px 10px;
	background: rgb(41, 54, 62);

	font-size: 13px;
	line-height: 1.1875;
	position: relative;
	z-index: 1;
	padding-bottom: 8px;
}

.chat_lang {
	float: left;
	color: #adbec6;
	text-transform: uppercase;
	width: calc(100% - 100px);
	white-space: nowrap;
	overflow: hidden;
	height: 30px;
}
.chat_room_arrow {
	transform: rotate(90deg);
	font-size: 11px;
	display: inline-block;
	margin-top: 2px;
	margin-left: 5px;
}
.chat_link {
	position: absolute;
	bottom: 0;
	right: 0;
	color: #64757c;
	font-weight: 500;
	font-size: 13px;
	cursor: pointer;
	outline: none;
	margin-bottom: -26px;
	margin-right: 5px;
}

.chat_link > * {
	display: inline-block;
	vertical-align: middle;
}

.chat_link:hover {
	color: #adbec6;
}

.chat_rooms.chat_lang_show .chat_link {
	color: #adbec6;
}

.chat_link .icon {
	padding-left: 5px;
	font-size: 10px;
}

.chat_link .icon.icon-down:before {
	font-size: 17px;
}

.chat_rooms_head:after {
	clear: both;
	display: block;
	content: "";
}

.chat_rooms_head .icon-angle-righticon {
	font-size: 20px;
	padding-left: 10px;
}

.chat_collapse {
	display: none;
	background-color: #526a76;
	padding: 0px 20px;
	text-align: right;
	font-size: 18px;
	line-height: 1;
	position: relative;
	z-index: 0;
}

.chat_collapse:hover {
	background-color: #607d8b;
}

.chatting_chat_msgs {
	position: relative;
	z-index: 0;
	height: 100%;
	background-color: #2f3e45;
}

.chat_input_hold {
	height: 100%;
	position: relative;
	z-index: 1;
	flex: 1;
	display: flex;
	flex-direction: column;
	box-shadow: 0 0 10px 0 #141a1d;
}

.chat_msgs {
	background-color: #2f3e45;
	padding: 0 4px;
	overflow-y: scroll;
	display: flex;
	flex: 1;
	position: relative;
	flex-direction: column;
	-webkit-overflow-scrolling: touch;
}

.chat_msgs:hover,
.chat_msgs:active,
.chat_msgs:focus {
	overflow-y: auto;
}

.chat_collapse:before {
	width: 100%;
	left: 0;
	top: 100%;
	background: linear-gradient(180deg, #2f3e45 0%, rgba(47, 62, 69, 0) 100%);
	content: "";
	position: absolute;
	height: 70px;
	pointer-events: none;
}

.chat_msg {
	padding: 3px 0px 3px 28px;
	position: relative;
	margin: 0 3px;
}
.msg_no_bg {
	background-color: none;
}
.chat_msg:nth-child(odd) {
	background-color: rgb(43, 56, 64);
}

.chat_user,
.chat_admin,
.chat_moderator,
.chat_checkmarked,
.chat_developer {
	font-size: 0;
	vertical-align: middle;
	display: inline-block;
	position: relative;
	margin-left: -26px;
}
.drop-down-container {
	z-index: 3 !important;
}
.emoji-mart {
	z-index: 3 !important;
}
.chat_user > *,
.chat_moderator > *,
.chat_admin > *,
.chat_checkmarked > *,
.chat_developer > * {
	display: inline-block;
	vertical-align: middle;
	margin-left: 5px;
}

.chat_user {
	cursor: pointer;
}

.bet_levels.crash,
.bet_levels.hilo {
	margin-left: 10px;
}

.bet_levels.roulette {
	margin-right: 5px;
}

.bet_levels > * {
	display: inline-block;
	vertical-align: middle;
}

.chat_user > *:first-child,
.chat_moderator > *:first-child,
.chat_admin > *:first-child,
.chat_checkmarked > *:first-child,
.chat_developer > *:first-child {
	margin-left: 0;
}

.chat_user_prof img {
	width: 24px;
	height: 24px;
	border-radius: 5px;
}

.chat_user_name {
	color: #acbfc6;

	line-height: 1;
}

.msg-admin-message .chat_user_name,
.msg-admin-message .icon-gammdom-symbol:before,
.msg-admin-message .chat_user_colen {
	color: #ff5a5a;
}

.msg-support-message .chat_user_name,
.msg-support-message .icon-gammdom-symbol:before,
.msg-support-message .chat_user_colen {
	color: #00e9ff;
}
.msg-developer-message .chat_user_name,
.msg-developer-message .icon-gammdom-symbol:before,
.msg-developer-message .chat_user_colen {
	color: orange;
}

.msg-moderator-message .chat_user_name,
.msg-moderator-message .icon-gammdom-symbol:before,
.msg-moderator-message .chat_user_colen {
	color: #fff275;
}

.msg-mute-message {
	position: relative;
}

.msg-mute-message > a {
	color: #10de7c;
}

.rain-message .chat_user_name,
.rain-message .icon-gammdom-symbol:before,
.rain-message .chat_user_colen {
	color: cornflowerblue;
}

.msg-bonus-message {
	color: #fff275;
	padding-left: 10px;
	font-size: 14px;
}

.msg-info-message {
	font-size: 14px;
	padding-left: 10px;
}

.chat_user_ico .icon-gammdom-symbol {
	font-size: 15px;
	color: #adbec6;
	display: inline-block;
	vertical-align: middle;
}

.chat_user_colen {
	display: inline-block;
	font-size: 14px;
	vertical-align: middle;
	padding: 0 5px 0 2px;
}

ul.messages li {
	font-size: 16px;
}

.chat_msg.msg-moderator-message,
.chat_msg.red {
	position: relative;
}

.chat_msg.red:before {
	position: absolute;
	right: 100%;
	top: 0;
	width: 4px;
	height: 100%;
	content: "";
}

.chat_msg.red:before {
	background-color: #ff5a5a;
}

.chat_msg.red .chat_user_name {
	color: #ff5a5a;
}

.chan_langs {
	position: absolute;
	left: 100%;
	z-index: 50;
	background-color: #36454c;
	box-shadow: 3px 2px 5px 0 rgba(34, 44, 49, 0.7);
}

.chat_rooms.chat_lang_show + .chat_langs {
	transform: translateX(0);
	opacity: 1;
	visibility: visible;
}

.chat_lang_show:after {
	width: 0;
	height: 0;
	border-right: 15px solid #36454c;
	border-top: 15px solid transparent;
	border-bottom: 15px solid transparent;
	position: absolute;
	top: -3px;
	right: 0;
	content: "";
	z-index: 2;
}

.chat_lan {
	color: #ffffff;
	font-size: 18px;
	font-weight: 300;
	line-height: 1.333333333333333;
	display: block;
}

.chat_lan_li {
	margin-top: 6px;
	position: relative;
}

.chat_lan_li:first-child {
	margin-top: 0;
}

.chat_lan:hover {
	color: #0ee07b;
}

.chat_lan_li.active .chat_lan {
	color: #607d8b;
}

.chat_lan_li.active.dis_link {
	pointer-events: none;
}

.lan_close {
	position: absolute;
	left: 100%;
	top: 0;
	width: 20px;
	height: 20px;
	margin-top: 2px;
}

.lan_close:before,
.lan_close:after {
	position: absolute;
	left: 0px;
	content: "";
	display: block;
	width: 15px;
	top: 6px;
	height: 2px;
	background-color: #656565;
	transition: background-color 0.4s;
}

.chat_lang .lan_close.hiddenX {
	width: 0;
	margin-left: 0;
}

.lan_close.hiddenX:before,
.lan_close.hiddenX:after {
	background-color: transparent;
	box-shadow: none;
}

.lan_close:before {
	transform: rotate(-45deg);
}

.lan_close:after {
	transform: rotate(45deg);
}

.emoji {
	position: absolute;
	top: 24px;
	right: 70px;
	font-size: 18px;
	color: #607d8b;
	margin-top: 2px;
}

.emoji:hover {
	color: #adbec6;
}

/* === XP LEVELS === */

.xp_admin,
.xp_0 {
	background-color: #3d5059;
}

.xp_40 {
	background-color: #3d5059;
}

.xp_50 {
	background-color: #adbec6 !important;
}

.xp_60 {
	background-color: #0ee07b !important;
}

.xp_70 {
	background-color: #4362ac !important;
}

.xp_80 {
	background-color: #71abfd !important;
}

.xp_90 {
	background-color: #f7f7f7 !important;
}

.xp_95 {
	background: rgb(169, 3, 41) !important; /* Old browsers */
	background: -moz-linear-gradient(
		top,
		rgba(169, 3, 41, 1) 0%,
		rgba(143, 2, 34, 1) 44%,
		rgba(109, 0, 25, 1) 100%
	) !important; /* FF3.6-15 */
	background: -webkit-linear-gradient(
		top,
		rgba(169, 3, 41, 1) 0%,
		rgba(143, 2, 34, 1) 44%,
		rgba(109, 0, 25, 1) 100%
	) !important; /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(
		to bottom,
		rgba(169, 3, 41, 1) 0%,
		rgba(143, 2, 34, 1) 44%,
		rgba(109, 0, 25, 1) 100%
	) !important; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.xp_100,
.xp_110,
.xp_120,
.xp_130,
.xp_140,
.xp_150 {
	background-color: #f9ff7f !important;
}

.xp_40.gamdom-logo .icon {
	font-size: 13px;
	color: #000;
}

.xp_50 .icon,
.xp_60 .icon,
.xp_70 .icon,
.xp_80 .icon,
.xp_90 .icon,
.xp_95 .icon {
	font-size: 17px;
	margin-top: 2px;
	color: #000;
}

.xp_40.gamdom-logo .icon-gammdom-symbol:before {
	font-size: 13px;
	color: #000;
}

.xp_100 .icon,
.xp_110 .icon,
.xp_120 .icon,
.xp_130 .icon,
.xp_140 .icon,
.xp_150 .icon {
	font-size: 24px !important;
	margin-bottom: -10px;
}

.xp_100 .icon {
	color: #fbd771;
}

.xp_110 .icon {
	color: #acbfc6;
}

.xp_120 .icon {
	color: #0ee16b;
}

.xp_130 .icon {
	color: #4462ab;
}

.xp_140 .icon {
	color: #72a9fd;
}

.xp_150 .icon {
	color: #fd5862;
}

.xp_admin,
.xp_0,
.xp_40,
.xp_50,
.xp_60,
.xp_70,
.xp_80,
.xp_90,
.xp_95,
.xp_100,
.xp_110,
.xp_120,
.xp_130,
.xp_140,
.xp_150 {
	font-size: 0;
	line-height: 1;
	border-radius: 5px;
	padding: 2px 5px;
	min-height: 20px;
	position: relative;
	text-align: right;
}

.icon.crown {
	width: 21px;
	height: 17px;
}

.icon.crown.zero_gems {
	content: url(../img/crown_0gem.gif);
}

.icon.crown.one_gems {
	content: url(../img/crown_1gem.gif);
}

.icon.crown.two_gems {
	content: url(../img/crown_2gem.gif);
}

.icon.crown.three_gems {
	content: url(../img/crown_3gem.gif);
}
.icon.crown.new {
	content: url(../img/crown_new.gif);
}

[class^="bet_level_"] {
	border-radius: 6px;
	color: #141a1d;
	font-size: 12px;
	font-weight: bold;
	text-align: center;
}

.bet_level_40 > .level_val {
	color: #adbec6 !important;
}

.bet_level_40 {
	border-bottom: 1.5px solid #1e292e;
	background: linear-gradient(#475862 20%, #3d4f59 80%);
}

.bet_level_50 {
	border-bottom-color: #727a7d;
	background: linear-gradient(#c1ced4 20%, #acbdc5 80%);
}

.bet_level_60 {
	border-bottom-color: #009954;
	background: linear-gradient(#06eaa2 20%, #02df7c 80%);
}

.bet_level_70 {
	border-bottom-color: #273c6c;
	background: linear-gradient(#4d6aae 20%, #4362aa 80%);
}

.bet_level_80 {
	border-bottom-color: #4d75ad;
	background: linear-gradient(#84b7fc 20%, #72abfb 80%);
}

.bet_level_90 {
	border-bottom-color: #c3c3c3;
	background: linear-gradient(#fbfbfb 20%, #f7f7f7 80%);
}

.bet_level_95 {
	border-bottom-color: rgb(141, 4, 36);
	background: rgb(169, 3, 41); /* Old browsers */
	background: -moz-linear-gradient(
		top,
		rgba(169, 3, 41, 1) 0%,
		rgba(143, 2, 34, 1) 44%,
		rgba(109, 0, 25, 1) 100%
	); /* FF3.6-15 */
	background: -webkit-linear-gradient(
		top,
		rgba(169, 3, 41, 1) 0%,
		rgba(143, 2, 34, 1) 44%,
		rgba(109, 0, 25, 1) 100%
	); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(
		to bottom,
		rgba(169, 3, 41, 1) 0%,
		rgba(143, 2, 34, 1) 44%,
		rgba(109, 0, 25, 1) 100%
	); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.bet_level_100,
.bet_level_110,
.bet_level_120,
.bet_level_130 {
	border-bottom-color: #afa442;
	background: linear-gradient(#fef8ae 20%, #fef177 80%);
}

[class^="crown_"] {
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	overflow: visible;
	position: absolute;
	width: 64%;
	height: 50%;
	bottom: -3px;
	right: -10px;
	z-index: 2;
}

.pl_entry [class^="crown_"] {
	right: -5px;
}

[class^="crown_"].crash {
	bottom: -1px;
	right: -7px;
}

[class^="crown_"].hilo {
	bottom: -2px;
	right: -7px;
}

.crown_100 {
	background-image: url(../img/crown_0gem.gif);
}

.crown_110 {
	background-image: url(../img/crown_1gem.gif);
}

.crown_120 {
	background-image: url(../img/crown_2gem.gif);
}

.crown_130 {
	background-image: url(../img/crown_3gem.gif);
}

.bet_level_40,
.bet_level_50,
.bet_level_60,
.bet_level_70,
.bet_level_80,
.bet_level_90,
.bet_level_95,
.bet_level_100,
.bet_level_110,
.bet_level_120,
.bet_level_130,
.bet_level_140,
.bet_level_150 {
	font-size: 0;
	line-height: 1.33;
	border-radius: 5px;
	padding: 2px 5px;
	min-height: 20px;
	position: relative;
	text-align: right;
}

[class^="xp_"] > .level_val {
	color: #141a1d;
	font-size: 12px;
	font-weight: bold;
	line-height: 1.33;
	padding-left: 5px;
	margin-top: 2px;
}

.xp_100 > .level_val,
.xp_110 > .level_val,
.xp_120 > .level_val,
.xp_130 > .level_val,
.xp_140 > .level_val,
.xp_150 > .level_val {
	padding-left: 25px;
}

[class^="bet_level_"] > .level_val {
	color: #141a1d;
	font-size: 12px;
	font-weight: bold;
	line-height: 1;
}

[class^="xp_"] > * {
	display: inline-block;
	vertical-align: middle;
}

[class^="bet_level_"] > * {
	vertical-align: middle;
}

.xp_0 .icon:not(.icon-checkmarked-user),
.xp_40:not(.gamdom-logo) .icon,
.xp_admin .icon {
	width: 1px;
	min-height: 18px;
}

.xp_0 .level_val,
.xp_40:not(.gamdom-logo) .level_val,
.xp_admin .level_val {
	color: #adbec6;
	padding-left: 0;
}

.level-YT {
	display: inline-flex !important;
}

.xp_0 .level_val,
.xp_admin .level_val {
	font-size: 10px;
}

/* === HOME PAGE LEVEL COLORS === */

/* === CHAT ICONS ETC. RESIZING === */

.messages img {
	width: 20px;
	height: 20px;
	border-radius: 5px;
}

.messages .icon,
.messages .icon-gammdom-symbol,
.bet_levels .icon {
	font-size: 13px;
}

.messages .level_val {
	font-size: 12px;
}

.messages .chat_user_name,
.messages .chat_cont {
	font-size: 14px;
}

/* === CHAT ROOM SECTION === */

.page_header {
	font-size: 0;
	padding: 20px 30px;
	min-height: 80px;
}

/* == COLUMNS == */

.col_full {
	width: 100%;
}

.col_half {
	width: 50%;
}

.col_one_third {
	width: 33.33%;
}

.col_two_third {
	width: 66.66%;
}
.icon-stopwatch {
	display: inline-block !important;
	margin-top: 1px;
}
.col_one_fourth {
	width: 25%;
}

.col_full,
.col_half,
.col_one_third,
.col_two_third,
.col_one_fourth {
	display: inline-block;
	padding: 0 15px;
}

.col_row {
	margin: 0 -15px;
}

.col {
	display: inline-block;
}

/* == COLUMNS == */

/* == HOME PAGE == */

.user_levels .level_val {
	padding: 0 !important;
	margin-left: 4px;
}
.user_level.hidden_lvl,
.user_xp.hidden_lvl {
	cursor: help;
}
.user_level.hidden_lvl {
	background: #3d5059;
	color: #adbec6;
	margin-right: 1px;
}
.user_level.hidden_lvl > .level_val {
	color: #adbec6;
	font-size: 18px;
	padding: 1px 10px;
	font-weight: 500;
	line-height: 1.166666666666667;
}
.user_level {
	color: #141a1d;
	font-weight: bold;
	line-height: 1.166666666666667;
	font-size: 0;
	width: 80px;
}

.user_level > * {
	display: inline-block;
	vertical-align: middle;
	font-size: 22px;
	line-height: 1;
}

.user_level .icon {
	font-size: 29px;
}

.user_level .level_val {
	padding-left: 7px;
}

.user_level .icon-user-xp {
	font-size: 47px !important;
	margin-bottom: -15px;
}

.user_xp {
	width: 260px;
	background-color: #3d4f59;
	margin-left: 5px;
	position: relative;
	overflow: hidden;
}

.user_xp.small {
	min-width: 50px;
	height: 100%;
	padding: 10px;
	margin-top: 2.5px;
}

.user_xp.small.best {
	transform: rotate(270deg);
	margin-top: 12px;
	min-width: 48px;
	margin-left: 0;
}

.xp {
	color: #adbec6;
	font-size: 18px;
	line-height: 1.166666666666667;
	text-align: center;
	position: relative;
}

.user_grid .xp_0 .level_val,
.user_grid .xp_40 .level_val,
.user_grid .xp_admin .level_val,
.user_grid .xp_youtuber .level_val,
.stats_details .xp_0 .level_val,
.stats_details .xp_40 .level_val,
.stats_details .xp_admin .level_val,
.stats_details .xp_youtuber .level_val {
	font-size: 16px;
}

.total_box {
	background: rgb(36, 48, 55);
	border-radius: 6px;
	color: rgb(255, 255, 255);
	font-size: 14px;
	font-weight: 500;
	text-align: center;
	padding: 12px 0;
	margin-top: 10px;
}
.total_box_modal {
	margin-top: 37px;
	margin-bottom: -10px;
}
.green {
	color: #23d160;
}
.bold {
	font-weight: bold;
}
.total_box .green {
	margin-left: 10px;
	display: inline-block;
}
.total_box .icon-gammdom-symbol {
	margin-right: 7px;
	font-size: 16px;
}

.disable {
	background-color: #41535d !important;
	color: #1b2428 !important;
	pointer-events: none;
	cursor: not-allowed;
}

.disabled {
	pointer-events: none;
}

.reset_net_profit {
	min-width: 120px;
	font-size: 14px;
	padding: 8px 20px;
	border-radius: 6px;
	margin-top: 10px;
}

.best_timing {
	border-radius: 8px;
	background: linear-gradient(44.72deg, #2f3e45 0%, #3d5059 100%);
	box-shadow: 0 0 10px 0 rgba(20, 26, 29, 0.75);
	padding: 20px;
}

.timing {
	color: #607d8b;
	font-size: 16px;
	line-height: 1.166666666666667;
	display: inline-block;
	padding-left: 10px;
}

.timing:hover,
.timing.active {
	color: #0ee07b;
}

.timing:before {
	content: "|";
	margin-right: 10px;
}

.timing:first-child {
	padding-left: 0;
}

.timing:first-child:before {
	display: none;
}

.biggest_grids {
	margin: 0 -10px;
	padding-top: 17px;
}

.biggest_grids .col_one_third {
	padding: 0 10px;
}

.biggest_grid {
	background: linear-gradient(45.15deg, rgba(20, 26, 29, 0.4) 0%, rgba(20, 26, 29, 0.25) 100%);
	border-radius: 8px;
}

.biggest_grid {
	text-align: center;
	padding: 18px 15px;
}

.biggest_grid .title {
	color: #adbec6;
	font-size: 17px;
	line-height: 1.166666666666667;
	text-transform: uppercase;
}

.aff_col {
	border-radius: 8px;
	background: linear-gradient(44.72deg, #2f3e45 0%, #3d5059 100%);
	box-shadow: 0 0 10px 0 #141a1d;
	padding: 28px 48px;
}

.aff_col .title,
.status_today .title {
	color: #adbec6;
	font-size: 24px;
	font-weight: 500;
	line-height: 1.166666666666667;
	text-transform: uppercase;
}

.aff_col .title {
	white-space: nowrap;
}

.field_group input {
	color: #adbec6;
	font-size: 16px;
	line-height: 1.166666666666667;
	border-bottom: 2px solid #607d8b;
	width: 100%;
	transition: border-color 0.4s;
	position: relative;
	z-index: 1;
}

.field_group {
	position: relative;
	margin-top: 34px;
}

.aff_code_fg {
	margin-top: 10px;
}

.placeholder_txt {
	color: #607d8b;
	font-size: 16px;
	line-height: 1.1875;
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	text-align: left;
	transition:
		bottom 0.4s,
		top 0.5s,
		font-size 0.5s,
		color 0.4s;
	padding-bottom: 5px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.field_group input:focus + .placeholder_txt,
.field_group .has-input + .placeholder_txt {
	bottom: 100%;
	font-size: 14px;
}

.field_group input:focus + .placeholder_txt {
	color: #0ee07b;
}

.field_group input:focus {
	border-color: #0ee07b;
}

.error_field .placeholder_txt {
	color: #ff5a5a;
}

.error_field input[type="text"] {
	border-bottom-color: #ff5a5a;
}

.helper_text {
	font-size: 12px;
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
	text-align: left;
	padding-top: 5px;
	color: #607d8b;
}

.error_field .helper_text {
	color: #ff5a5a;
}

.clear_text {
	position: absolute;
	right: 0;
	bottom: 0;
	padding-bottom: 7px;
	font-size: 12px;
	color: #607d8b;
	opacity: 0;
	visibility: hidden;
	z-index: 1;
	transition: 0.2s color ease-out;
}

.clear_text:hover {
	color: rgba(255, 255, 255, 0.8);
}

.has-input ~ .clear_text {
	opacity: 1;
	visibility: visible;
}

.field_group input::-webkit-input-placeholder {
	/* Chrome/Opera/Safari */
	color: #607d8b;
}

.field_group input::-moz-placeholder {
	/* Firefox 19+ */
	color: #607d8b;
}

.field_group input:-ms-input-placeholder {
	/* IE 10+ */
	color: #607d8b;
}

.field_group input:-moz-placeholder {
	/* Firefox 18- */
	color: #607d8b;
}

.aff_col .btn {
	margin-top: 35px;
}

.twitter_feed {
	border-radius: 8px;
	background: linear-gradient(44.72deg, #2f3e45 0%, #3d5059 100%);
	box-shadow: 0 0 10px 0 rgba(20, 26, 29, 0.75);
	padding: 30px;
}

.twitt_head {
	color: #adbec6;
	font-size: 20px;
	line-height: 1.4;
	font-weight: 300;
}

.twitt_head a {
	color: #0ee07b;
	font-weight: normal;
}

.twitt_head .icon-twitter2icon {
	color: #0ee07b;
	font-size: 18px;
}

.twitt_head .icon-gammdom-symbol {
	font-size: 15px;
}

.grid_name,
.earn_points {
	color: #adbec6;
	font-size: 16px;
	line-height: 1.166666666666667;
	padding-top: 25px;
}

.earn_points {
	padding-top: 3px;
}

.g_img {
	margin-top: 20px;
}

.g_img img {
	height: 88px;
	width: auto;
}

.col_grids {
	margin-top: -15px;
}

.col_half.casino_grid,
.col_half.jackpot_grid,
.col_two_third.big_grids,
.col_one_third.aff_grid,
.col_one_third.daily_grid,
.col_one_third.twitter_grid,
.col_one_third.crash_grid,
.col_one_third.roulette_grid,
.col_one_third.hilo_grid,
.col_one_third.tradeup_grid,
.col_one_third.status_grid,
.col_two_third.graph_grid,
.col_one_third.last_grid {
	float: left;
	padding: 0;
}
.col_half.casino_grid .medium_grid_pad,
.col_half.jackpot_grid .medium_grid_pad {
	padding-top: 50%;
}

.small_gird_pad {
	position: relative;
	padding-top: 53.54%;
}

.medium_grid_pad {
	position: relative;
	padding-top: 79.16%;
}

.twitter_grid_pad {
	position: relative;
	padding-top: 158.33%;
}

.twitter_grid_pad.not-loggedin {
	padding-top: 75%;
}

.padd_abs,
.biggest_grid {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	padding: 9px 15px;
}

.best_timing,
.aff_col,
.twitter_feed,
.biggest_grid,
.status_today,
.statistics {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.aff_col--autoh {
	height: auto;
}

.aff_col .field_group {
	width: 100%;
}

.best_timing > .text_center {
	width: 100%;
	height: 100%;
}

.status_grid_pad {
	position: relative;
	padding-top: 75%;
}

.graph_grid_pad {
	position: relative;
	padding-top: 37.5%;
}

/* == HOME PAGE == */

/* == PROFILE PAGE == */

.statistics {
	padding: 15px 20px 20px 20px;
}

.Select-placeholder {
	font-size: 13px;
}

.informations {
	padding: 30px;
}

.information {
	display: inline-block;
	width: 20%;
	text-align: center;
	position: relative;
}

.information:before {
	position: absolute;
	left: 0;
	top: 0;
	width: 2px;
	height: 90%;
	background-color: #79858a;
	content: "";
	margin-top: 5%;
}

.information:first-child:before {
	display: none;
}

.information .icon {
	font-size: 40px;
	color: #0ee07b;
	margin-top: 35px;
	display: block;
}

/* == PROFILE PAGE == */

/* == OVERLAYS == */

.ReactModalPortal {
	position: fixed;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	overflow: auto;
	z-index: 130;
}

.ReactModal__Overlay {
	width: 100%;
	position: relative;
	height: 100%;
	padding: 40px;
	background-color: rgba(0, 0, 0, 0.6);
	backdrop-filter: blur(8px);
}

.ReactModal__Content {
	padding: 0px;
	max-width: 610px;
	width: 100%;
	position: absolute;
	top: 55%;
	left: 50%;
	transform: translate(-50%, -50%);
	color: #fff;
}

@media only screen and (max-width: 600px) {
	.ReactModal__Content {
		top: 51%;
	}
}

.ReactModal__Content > div {
	height: 100%;
	max-height: 710px;
	border-radius: 10px;
	background: #131a22;
	box-shadow: 0 0 10px 0 #141a1d;
	text-align: center;
	min-height: 100px;
	overflow: auto;
}

.ReactModalPortal .bbb,
.ReactModalPortal .bbb {
	border-radius: 0;
	background: none;
	box-shadow: none;
}

.table {
	color: #d5dee2;
	font-size: 14px;
}

.ReactModalPortal .table {
	width: 100%;
}

.history_overlay .table {
	height: calc(100% - 54px);
}

.trade_offers_pop .game_head {
	padding: 0;
	background: none;
}

.trade_offer {
	padding: 20px 25px;
	position: relative;
}

.trade_offer:nth-child(odd) {
	background-color: rgba(34, 44, 49, 0.43);
}

.ReactModal__Content.dialog-modal-content {
	max-width: 430px;
	margin: auto;
	outline: none;
}

.ReactModal__Content.dialog-modal-content > div {
	width: 100%;
	height: auto;
	outline: none;
	padding: 32px 24px;
	position: relative;
}

.ReactModal__Content.dialog-modal-content .ui-dialog .modal-close-button {
	position: absolute;
	right: 10px;
	top: 10px;
	line-height: 1ex;
	font-size: 30px;
	z-index: 1;
	margin-top: 2px;
	color: #0ee07b;
}

.ReactModal__Content.dialog-modal-content .ui-dialog .modal-close-button > div:after {
	content: "\D7";
}

.ReactModal__Content.dialog-modal-content .ui-dialog .modal-close-button:hover {
	color: #0a7843;
}

.ReactModal__Content.dialog-modal-content .ui-dialog .dialogText {
	color: #adbec6;
	font-size: 20px;
	line-height: 1.2;
}

.ReactModal__Content.dialog-modal-content .ui-dialog-titlebar {
	font-size: 0;
}

.ReactModal__Content.dialog-modal-content .form-group {
	padding-top: 20px;
	text-align: left;
	position: relative;
}

.ReactModal__Content.dialog-modal-content .form-group .lbl_head {
	color: #607d8b;
	font-size: 20px;
	line-height: 1.2;
	display: block;
	margin-bottom: 3px;
}

.ReactModal__Content.dialog-modal-content .form-group .form-control {
	color: #adbec6;
	font-size: 24px;
	line-height: 1.166666666666667;
	padding-bottom: 5px;
	border-bottom: 2px solid #607d8b;
	width: 100%;
	transition: border-color 0.4s;
	position: relative;
	z-index: 1;
	font-weight: 500;
	padding-left: 40px;
}

.ReactModal__Content.dialog-modal-content .form-group .input_mark {
	position: absolute;
	left: 0;
	bottom: 7px;
	top: auto;
	font-size: 0px;
	opacity: 1;
	visibility: visible;
	transition:
		opacity 0.4s,
		visibility 0.4s;
	line-height: 1;
	color: #adbec6;
}

.ReactModal__Content.dialog-modal-content .form-group .input_mark .icon {
	font-size: 24px;
}

pre {
	display: block;
	padding: 9.5px;
	margin: 0 0 25px;
	font-size: 13px;
	line-height: 1.42857143;
	color: #333;
	background-color: #f5f5f5;
	border: 1px solid #ccc;
	border-radius: 4px;
	font-family: monospace, monospace;
	white-space: pre-wrap;
	white-space: -moz-pre-wrap;
	white-space: -pre-wrap;
	white-space: -o-pre-wrap;
	word-wrap: break-word;
}

/* == OVERLAYS == */

/* == HELP PAGES == */

.heading,
.description {
	line-height: 1.3125;
}

.heading {
	font-size: 18px;
	margin-top: 20px;
}

.heading:first-child {
	margin-top: 0;
}

.description {
	color: #d4d8d9;
	font-size: 16px;
}

.description > p {
	margin-top: 15px;
}

.steamid {
	/* font-style: italic; */
	color: #607c87;
	margin-left: 3px;
}

.mailto:hover {
	color: #0a7843;
}

.sidebar_link {
	font-size: 16px;
	line-height: 1.1875;
	color: #d4d8d9;
	padding: 7px 0;
	display: block;
	margin-top: 5px;
}

.sidebar_links li:first-child .sidebar_link {
	padding-top: 0;
	margin-top: 0px;
}

.sidebar_link:hover,
.sidebar_link.active {
	color: #01e66d;
}

.faq .search,
.search_filter .search {
	border-radius: 6px;
	background-color: #2f3e45;
	box-shadow: 0 0 9px 0 rgba(96, 125, 139, 0.47);
	max-width: 350px;
	padding: 5px;
	margin-left: auto;
	position: relative;
	min-height: 30px;
	display: flex;
}

.faq .search .search-icon,
.search_filter .search .search-icon {
	width: 25px;
	justify-content: center;
	display: flex;
	align-items: center;
	font-size: 14px;
}

.faq .search input,
.search_filter .search input {
	width: 100%;
	color: #adbec6;
}

.faq .search input::-webkit-input-placeholder,
.search_filter .search input::-webkit-input-placeholder {
	/* Chrome/Opera/Safari */
	color: #adbec6;
}

.faq .search input::-moz-placeholder,
.search_filter .search input::-moz-placeholder {
	/* Firefox 19+ */
	color: #adbec6;
}

.faq .search input:-ms-input-placeholder,
.search_filter .search input:-ms-input-placeholder {
	/* IE 10+ */
	color: #adbec6;
}

.faq .search input:-moz-placeholder,
.search_filter .search input:-moz-placeholder {
	/* Firefox 18- */
	color: #adbec6;
}

.faq .inner {
	display: none;
	padding-bottom: 20px;
}

.faqs {
	margin-top: 20px;
}

.static_page .page_head {
	padding: 20px 0;
}

.helper_txt {
	color: #607d8b;
	font-size: 12px;
	line-height: 1.166666666666667;
}

.faq_single .f_ques {
	color: #0ee07b;
	font-size: 20px;
	line-height: 1.2;
	margin-top: 5px;
	position: relative;
}

.faq_single .toggle,
.faq_single .toggle > span {
	display: block;
}

.faq_single .toggle {
	display: flex;
	padding-top: 10px;
	padding-bottom: 20px;
}

.faq_single {
	border-top: 1px solid #607d8b;
}

.faq_single:first-child {
	border: none;
}

.faq_single.active .toggle {
	padding-bottom: 15px;
}

.faq .faq_single:last-child .inner {
	padding-bottom: 0;
	padding-top: 15px;
}

.faq .faq_single:last-child .toggle {
	padding-bottom: 0;
}

/* == HELP PAGES == */

/* == CRASH PG == */

.game_history,
.jackpot {
	display: inline-block;
	vertical-align: middle;
}

.pro_digit > span {
	vertical-align: bottom;
	line-height: 1;
	display: inline-block;
}

.pro_digit > span:last-child {
	font-size: 18px;
	margin-bottom: 1px;
}

.game_history {
	font-size: 0;
	margin-left: 15px;
}

.game_history .icon {
	font-size: 26px;
	color: #adbec5;
	transition: color 0.5s;
}

.game_history:hover .icon {
	color: #607d8b;
}

.jackpot {
	background-color: #fcee71;
	border: 1.7px solid #fff275;
	border-radius: 8px;
	box-shadow: 0 0 3px 0 rgba(255, 242, 117, 0.86);
	display: inline-flex;
	margin-left: 35px;
	padding-left: 50px;
	position: relative;
	width: fit-content;
}
.jackpot .symbol {
	align-items: center;
	background: linear-gradient(to bottom, #364751 0%, #526876 50%, #384a51 100%);
	border-right: 1px solid #141a1d;
	color: #fcee71;
	display: flex;
	font-size: 16px;
	font-weight: bold;
	height: 30px;
	justify-content: center;
	width: 30px;
}

.jackpot:active {
	background-color: #fcee71;
}

.jackpot .text {
	color: #fff275;
	font-size: 17px;
	font-weight: 500;
	line-height: 20px;
	position: absolute;
	bottom: 100%;
	left: 0;
	text-transform: uppercase;
	margin-bottom: -1px;
}

.jackpot .count {
	position: relative;
	font-size: 18px;
	color: #fff275;
	text-align: right;
	background: #364751;
	/* Old browsers */
	background: -moz-linear-gradient(top, #364751 0%, #526876 50%, #384a51 100%);
	/* FF3.6-15 */
	background: -webkit-linear-gradient(top, #364751 0%, #526876 50%, #384a51 100%);
	/* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, #364751 0%, #526876 50%, #384a51 100%);
	/* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	line-height: 1;
	padding: 6px 0 6px 2px;
	border-radius: 0 8px 8px 0;
	letter-spacing: 0.3em;
}

.player_table {
	padding: 15px 5px;
	height: 100%;
}

.pl_table_data {
	overflow-y: auto;
	height: calc(100% - 55px);
	-webkit-overflow-scrolling: touch;
}

.player_table .player_table_head {
	margin: 0 10px;
	border-bottom: 2px solid #607d8b;
	padding-bottom: 15px;
}

.table_titles {
	padding: 5px 10px;
	color: #adbec6;
}

.pl_count > *,
.pl_data > *,
.pl_name,
.pl_name span {
	display: inline-block;
	vertical-align: middle;
}

.table_titles .pl_name span {
	padding-left: 0;
}

.pl_count .icon {
	font-size: 22px;
	color: #adbec6;
}

.pl_count .pl_onli {
	color: #adbec6;
	font-size: 16px;
	line-height: 1.1875;
}

.pl_count {
	display: inline-block;
}

.pl_count.fr .icon,
.pl_count.mid .icon {
	font-size: 16px;
}

.pl_count.mid {
	position: absolute;
	left: 50%;
	transform: translate(-50%);
}

.pl_data {
	font-size: 0;
	padding: 5px 10px;
	color: #adbec6;
}

.pl_data:nth-child(even) {
	background-color: rgba(33, 42, 47, 0.4);
}

.pl_name {
	width: 51%;
}

.pl_link {
	display: block;
}

a.pl_link:hover {
	color: #607d8b;
}

.pl_name img {
	width: 24px;
	height: 24px;
	border-radius: 3px;
}

.pl_game,
.pl_bet,
.pl_profit,
.pl_no {
	width: 16%;
	display: inline-block;
	text-align: right;
	font-size: 14px;
	line-height: 1.142857142857143;
	padding-left: 20px;
	white-space: nowrap;
}

.pl_name_txt {
	font-size: 14px;
	font-weight: 500;
	line-height: normal;
	padding-left: 5px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: calc(100% - 80px);
}

.text_hold .field_group input,
.field_group.input_ico input {
	padding-left: 5px;
}

.text_hold .field_group input:focus ~ .input_mark,
.text_hold .field_group .has-input ~ .input_mark,
.field_group.input_ico input:focus ~ .input_mark,
.field_group.input_ico .has-input ~ .input_mark {
	opacity: 1;
	visibility: visible;
}

.bet_btn {
	border-radius: 4px;
	background-color: #adbec6;
	width: 11.92%;
	padding: 7px 5px;
	margin-left: 4px;
	color: #222c31;
	font-size: 14px;
	line-height: 1.153846153846154;
	text-transform: uppercase;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	transition: all 0.2s ease-out;
}

.bet_btn:hover {
	background-color: #d5e5ec;
	box-shadow: 0 2px #a9b8be;
	transform: translateY(-2px);
}

.bet_btn:active {
	box-shadow: 0 1px 0 0 #74878f;
	transform: translateY(-1px);
}

.text_hold .bet_btn:first-child {
	margin-left: 0;
}

.c_select,
.c_input {
	vertical-align: middle;
	display: inline-block;
}

.c_select {
	padding-top: 20px;
	width: 60%;
	border-bottom: 2px solid #607d8b;
	padding-bottom: 3px;
}

.c_select select {
	background-color: transparent;
	color: #adbec6;
	font-size: 16px;
	line-height: 1.1875;
	width: 100%;
	padding-right: 20px;
	position: relative;
	z-index: 1;
}

.c_select .helper_text {
	top: 0;
	padding-top: 0;
}

.c_sel {
	position: relative;
}

.c_input {
	width: 40%;
}

.text_hold .field_group .c_in input {
	padding-left: 0;
	padding-right: 0;
}

.text_hold .field_group input[disabled] {
	color: rgba(96, 125, 139, 0.5);
	border-color: rgba(96, 125, 139, 0.5);
	border-style: dotted;
}

/* == CRASH PG == */

/* == ROULETTE PG == */

.roll {
	margin: 10px -30px;
	background-color: #000000;
	box-shadow: 0 0 15px 6px rgba(0, 0, 0, 0.5);
	padding: 2px;
	position: relative;
}

.roll:before,
.roll:after {
	content: "";
	position: absolute;
	top: 0;
	height: 100%;
	width: 20px;
	z-index: 2;
}

.roll:before {
	background: linear-gradient(270deg, rgba(20, 26, 29, 0) 0%, #141a1d 100%);
	left: 0;
}

.roll:after {
	background: linear-gradient(270deg, rgba(20, 26, 29, 0) 0%, #141a1d 100%);
	transform: rotate(180deg);
	right: 0;
}

.numbers {
	white-space: nowrap;
}

.numbers:before {
	content: "";
	position: absolute;
	left: 0;
	width: 100%;
}

.numbers:before {
	bottom: 0;
	background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
	height: 25px;
}

.numbers-arrow-helper .number:after {
	height: 0;
}

@keyframes winnerEffectAnim {
	0% {
		left: 137%;
	}
	100% {
		left: -47%;
	}
}

.number.winner .winnerEffect {
	position: absolute;
	height: 140%;
	top: -20%;
	width: 10%;
	left: 137%;
	background: rgba(255, 255, 255, 0.5);
	transform: rotate(-30deg);
	animation-name: winnerEffectAnim;
	animation-duration: 2s;
	-webkit-box-shadow: 0px 0px 15px 8px rgba(255, 255, 255, 0.5);
	box-shadow: 0px 0px 15px 8px rgba(255, 255, 255, 0.5);
}

.number.winner {
	z-index: 1;
	overflow: hidden;
}

/* .number.winner::after,
.number.winner::before {
	display: none;
} */

.numbers-arrow-helper .number {
	border: none;
}

.numbers-arrow-helper .number:before {
	background: none;
}

.entry_btn.red {
	background: #ff7a7b;
	/* Old browsers */
	background: -moz-linear-gradient(top, #ff7a7b 0%, #ff7a7b 40%, #ff5959 40%, #ff5959 40%, #ff5959 100%);
	/* FF3.6-15 */
	background: -webkit-linear-gradient(top, #ff7a7b 0%, #ff7a7b 40%, #ff5959 40%, #ff5959 40%, #ff5959 100%);
	/* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, #ff7a7b 0%, #ff7a7b 40%, #ff5959 40%, #ff5959 40%, #ff5959 100%);
	/* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.entry_btn.black {
	background: #333333;
	/* Old browsers */
	background: -moz-linear-gradient(top, #333333 0%, #333333 40%, #000000 40%, #000000 40%, #000000 100%);
	/* FF3.6-15 */
	background: -webkit-linear-gradient(top, #333333 0%, #333333 40%, #000000 40%, #000000 40%, #000000 100%);
	/* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, #333333 0%, #333333 40%, #000000 40%, #000000 40%, #000000 100%);
	/* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.entry_btn.green {
	background: #3fe694;
	/* Old browsers */
	background: -moz-linear-gradient(top, #3fe694 0%, #3fe696 40%, #0ee07b 40%, #0ee07b 40%, #0ee07b 100%);
	/* FF3.6-15 */
	background: -webkit-linear-gradient(top, #3fe694 0%, #3fe696 40%, #0ee07b 40%, #0ee07b 40%, #0ee07b 100%);
	/* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, #3fe694 0%, #3fe696 40%, #0ee07b 40%, #0ee07b 40%, #0ee07b 100%);
	/* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.ReactModal__Content.dialog-modal-content .form-group .input_mark {
	position: absolute;
	left: 0;
	bottom: 7px;
	top: auto;
	font-size: 0px;
	opacity: 1;
	visibility: visible;
	transition:
		opacity 0.4s,
		visibility 0.4s;
	line-height: 1;
	color: #adbec6;
}

.roul_input,
.roul_btns,
.roul_controls .dd_hold {
	display: inline-block;
	vertical-align: bottom;
	margin-top: 25px;
}

.roul_input {
	width: 31.2%;
}

.roul_btns {
	width: 50%;
	padding-left: 30px;
}

.entry_btn {
	color: #ffffff;
	font-size: 20px;
	font-weight: 500;
	line-height: 1.2;
	text-align: center;
	box-shadow: 0 0 10px 0 rgba(34, 37, 39, 0.7);
	display: block;
	position: relative;
	border-radius: 6px;
}

.entry_btn span {
	border-radius: 6px;
	padding: 15px;
	display: block;
	position: relative;
}

.entry_btn span:before {
	position: absolute;
	bottom: 0;
	left: 0;
	content: "";
	width: 100%;
	height: 18px;
	background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
	border-radius: 0 0 6px 6px;
}

.entry_btn span:after {
	position: absolute;
	top: 0;
	left: 0;
	content: "";
	width: 100%;
	height: 6px;
	background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
	border-radius: 6px 6px 0 0;
}

.entry_btn:before {
	opacity: 0;
	transition: opacity 0.4s;
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	border-radius: 6px;
}

.entry_btn:hover:before {
	opacity: 1;
}

.entry_btn:active {
	transform: scale(0.9925) perspective(1px) !important;
}

.entry_btn:hover {
	transform: scale(1.0075) perspective(1px);
}

.entry_btn.red:before {
	background: #ca5657;
	/* Old browsers */
	background: -moz-linear-gradient(top, #ca5657 1%, #ca5657 40%, #c44443 40%, #c44443 40%, #c44443 100%);
	/* FF3.6-15 */
	background: -webkit-linear-gradient(top, #ca5657 1%, #ca5657 40%, #c44443 40%, #c44443 40%, #c44443 100%);
	/* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, #ca5657 1%, #ca5657 40%, #c44443 40%, #c44443 40%, #c44443 100%);
	/* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.entry_btn.green:before {
	background: #228556;
	/* Old browsers */
	background: -moz-linear-gradient(top, #228556 0%, #228556 40%, #ca5657 40%, #097843 40%, #097843 100%);
	/* FF3.6-15 */
	background: -webkit-linear-gradient(top, #228556 0%, #228556 40%, #ca5657 40%, #097843 40%, #097843 100%);
	/* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, #228556 0%, #228556 40%, #ca5657 40%, #097843 40%, #097843 100%);
	/* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.entry_btn.black:before {
	background: #445157;
	/* Old browsers */
	background: -moz-linear-gradient(top, #445157 0%, #445157 40%, #2f3e45 40%, #2f3e45 40%, #2f3e45 100%);
	/* FF3.6-15 */
	background: -webkit-linear-gradient(top, #445157 0%, #445157 40%, #2f3e45 40%, #2f3e45 40%, #2f3e45 100%);
	/* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, #445157 0%, #445157 40%, #2f3e45 40%, #2f3e45 40%, #2f3e45 100%);
	/* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.entry_plyr,
.entry_amt {
	white-space: nowrap;
	display: inline-block;
	vertical-align: bottom;
}

.entry_plyr {
	width: 70%;
	padding-right: 15px;
}

.entry_amt {
	width: 30%;
	text-align: right;
}

.entry_amt_txt {
	line-height: 1;
}

.entry_amt .icon {
	margin-right: 5px;
}

.top_entry img {
	width: 48px;
	height: 48px;
	border-radius: 5px;
}

.top_txt {
	font-size: 16px;
	font-weight: 500;
	line-height: 1.1875;
	text-transform: uppercase;
	display: block;
}

.image_section {
	max-width: 32px;
	position: relative;
	display: inline-block;
}

/* == ROULETTE PG == */

/* == HILO == */

.game_controls {
	padding: 20px;
}

.top_shade {
	position: absolute;
	background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
	border-radius: 0 0 6px 6px;
	left: 0;
	top: 0;
	width: 100%;
	height: 4px;
	content: "";
}

.col_row.btns_clr {
	margin: 0;
}

.btns_clr .col_one_third,
.btns_normal .col_one_fourth {
	padding: 0 5px;
}

.col_row.btns_normal {
	margin: 0;
}

.btns_normal .btn_clr {
	background-color: #adbec6;
	color: #222c31;
	padding: 5px 10px;
}
.card {
	display: inline-block;
	padding-right: 10px;
}

.joker .icon {
	font-size: 30px;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

.history_card.joker {
	background-color: #0a7843;
}

.history_card.joker .icon {
	color: #fff;
}

/* == HILO == */

/* == TRADEUP == */

.trade_txt {
	font-size: 18px;
	font-weight: 500;
	line-height: 1.166666666666667;
	padding-left: 10px;
}

.tradeup {
	font-size: 0;
	display: inline-block;
	background-color: rgba(49, 55, 57, 0.3);
	margin-left: 2px;
	padding: 5px;
	min-width: 78px;
	border-bottom: 2px solid transparent;
}

.tradeup.itemvalue-g5k {
	border-color: #85bb8c;
}

.tradeup.itemvalue-g10k {
	border-color: #08a052;
}

.tradeup.itemvalue-g200k {
	border-color: #f1cd62;
}

.tradeup.itemvalue-g500k {
	border-color: #ca3134;
}

.tradeup.itemvalue-g50k {
	border-color: #005de8;
}

.tradeup.itemvalue-g100k {
	border-color: #f1cd62;
}

.tradeup.itemvalue-g20k {
	border-color: #08a052;
}

.tradeup:first-child {
	margin-left: 0;
}

.trade_offer .items {
	margin-left: -9px;
	margin-top: 10px;
}

.trade_offer .item {
	display: inline-block;
	width: 25%;
	max-width: 138px;
	padding-left: 9px;
	padding-top: 10px;
}

.weapon {
	background: linear-gradient(45.15deg, rgba(20, 26, 29, 0.4) 0%, rgba(20, 26, 29, 0.25) 100%);
	border-radius: 3.94px;
	padding: 10px;
	display: block;
	position: relative;
	border: 1px solid #2e3b43;
	text-align: center;
	transition: border 0.4s;
	height: 100%;
}

.weapon.selected {
	border: 1px solid #0ee07b;
}

.weapon:hover {
	border-color: #0a7843;
}

.w_price {
	display: inline-block;
	font-size: 14px;
	font-weight: 500;
	line-height: 1.142857142857143;
	vertical-align: middle;
	padding-left: 5px;
}

.taken_txt {
	display: none;
}

.s_left {
	width: 21.5%;
	display: inline-block;
}
/* == TRADEUP == */

/* == MARKERPLACE == */

.filters {
	float: right;
}

.check_product {
	height: calc(100% - 172px);
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
}

.check_product .product {
	padding: 17px 15px;
	background: linear-gradient(45.15deg, #141a1d70 0%, #242d31 100%);
	border-radius: 7.03px;
	margin-top: 10px;
	position: relative;
}

.check_product .product:first-child {
	margin-top: 0;
}

.check_product .lan_close {
	left: auto;
	right: 0;
	margin-top: 7px;
	margin-right: 7px;
	width: 15px;
	height: 15px;
	z-index: 2;
}

/* == MARKERPLACE == */

.f0 {
	font-size: 0;
}

.p30 {
	padding: 30px;
}

.txt_red {
	color: #ff5959;
}

.txt_green {
	color: #0ee07b;
}

.txt_black {
	color: #000000;
}

.txt_white {
	color: #fff;
}

.inactive {
	color: #607d8b;
}

.mla {
	margin-left: auto;
}

.fl {
	float: left;
}

.fr {
	float: right;
}

.oh {
	overflow: hidden;
}

.mt20 {
	margin-top: 20px;
}

.mt25 {
	margin-top: 25px;
}

.mt10 {
	margin-top: 10px;
}

.fb {
	font-weight: 500;
}

.ptb0 {
	padding-top: 0;
	padding-bottom: 0;
}

.ptb25 {
	padding-top: 25px;
	padding-bottom: 25px;
}

.pt30 {
	padding-top: 30px;
}

/* == SCROLLBAR == */

::-webkit-scrollbar {
	width: 5px;
	height: 4px;
	background-color: transparent;
}

::-webkit-scrollbar-corner {
	background-color: transparent;
}

::-webkit-scrollbar-track {
	background: transparent;
}

::-webkit-scrollbar-thumb {
	border: none;
	-webkit-border-radius: 2px;
	background-color: #1c2329;
}

/* == SCROLLBAR == */

/* == STATIC == */

.static .c_dd {
	padding: 7px 8px;
}

/* == STATIC == */

.Select.is-focused:not(.is-open) > .Select-control {
	background: #607d8b;
}
.Select.is-open > .Select-control .Select-arrow {
	top: 50% !important;
}
.Select.is-focused > .Select-control {
	background-color: #3d5059;
}

.Select-clear-zone {
	width: 65px;
	color: #fff;
}

.Select-clear-zone:hover,
.Select-placeholder,
.Select--single > .Select-control .Select-value,
.Select.has-value.Select--single > .Select-control .Select-value .Select-value-label,
.Select.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value .Select-value-label {
	color: #fff;
}

.c_dd .Select-placeholder,
.c_dd .Select--single > .Select-control .Select-value,
.Select.has-value.Select--single > .Select-control .Select-value .Select-value-label,
.Select.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value .Select-value-label {
	color: #fff;
}

.c_dd .Select-placeholder,
.Select--single > .Select-control .Select-value {
	padding-right: 20px;
}

.c_sel {
	font-size: 14px;
}

.c_sel .Select-control {
	background: transparent;
	border: none !important;
	height: 20px;
}

.c_sel .Select-placeholder {
	line-height: 20px;
	padding-left: 0;
	color: #adbec6;
}

.c_sel .Select-control .Select-input {
	height: 20px;
	background: transparent;
}

.c_sel .Select-arrow-zone {
	font-size: 0;
}

.c_sel .Select.is-focused > .Select-control {
	background: transparent;
}

.progress_level {
	transform-origin: left top;
}

/* HILO */

.icon.icon-angle-down.select-caret {
	position: absolute;
	top: 50%;
	right: 6px;
	font-size: 24px;
	line-height: 0.7;
	transform: translateY(-50%);
}
.inventory {
	margin-left: -4px;
	margin-top: 16px;
	height: calc(100% - 110px);
}

.inventory-coins-icon {
	font-size: 60px;
	color: #607d8b;
	top: 20%;
	position: relative;
}

.faq_description {
	margin-top: 0;
	margin-left: 55px;
	color: #d4d8d9;
	font-size: 16px;
	line-height: 1.3125;
}

.faqs_container .faq_single .toggle {
	padding-top: 0;
}

.faqs_container .helper_txt {
	text-transform: uppercase;
	padding-top: 10px;
	padding-left: 30px;
	display: block;
}

.faqs_container .table {
	text-align: left;
	margin-top: 15px;
}

.faqs_container .table th {
	font-weight: 300;
}

.faqs_container .table th,
.faqs_container .table td {
	padding-right: 15px;
}

.faqs_container .inner {
	margin-top: -10px;
}

.faqs_container h5 {
	margin-top: 15px;
	margin-bottom: 10px;
	font-size: 16px;
	font-weight: 400;
}

.sub_heading {
	font-size: 18px;
	margin-top: 20px;
	/* margin-left: 20px; */
}

.rc-tooltip-hidden {
	display: none !important;
}
.rc-tooltip .bet_div {
	display: none;
}

.rc-tooltip.tradeup-tooltip.rc-tooltip-placement-bottom .rc-tooltip-arrow {
	border-bottom-color: #3b4e57;
}

.rc-tooltip.tradeup-tooltip .rc-tooltip-inner {
	border-radius: 6px;
}

.site_content > .messages_overlay {
	position: fixed;
	left: 0;
	top: 0;
	transform: none;
	width: 100%;
	height: 100%;
	z-index: 131123;
	padding: 15px;
	/* pointer-events: none; */
}

.messages_overlay .notification_pop {
	left: 50%;
	transform: translateX(-50%);
	margin: 0;
	pointer-events: auto;
}

.notification_pop.bbb {
	box-shadow: 0 0 30px 10px #141a1d;
}

.splash_overlay {
	background-color: #232c31;
}

.splash_overlay:before {
	content: "";
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
}

.splash_overlay .middle {
	background-color: rgba(35, 44, 49, 0.94);
}

.rounded.not-found {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	border-radius: 8px;
	background: linear-gradient(44.72deg, #2f3e45 0%, #3d5059 100%);
	box-shadow: 0 0 10px 0 #141a1d;
}

.chat_msgs .drop-down-container {
	box-shadow: 0 0 10px 0 #141a1d;
	background: linear-gradient(44.72deg, #2f3e45 0%, #3d5059 100%);
	border-radius: 6px;
	padding: 15px;
	min-width: 115px;
	font-size: 14px;
	color: #adbec6;
	position: absolute;
	left: 25px;
	top: 100%;
	z-index: 1;
	margin-top: 2px;
}

.chat_msgs .drop-down-container li:first-child {
	margin-top: 0;
}

.chat_msgs .drop-down-container li {
	margin-top: 10px;
	cursor: pointer;
}

.chat_msgs .drop-down-container li a {
	display: block;
}

.chat_msgs .drop-down-container li:hover,
.chat_msgs .drop-down-container li a:hover {
	color: #fff;
}

.errorLogo {
	width: 200px;
	margin: 0px 15px 30px;
}

.stat_not_found {
	background: linear-gradient(45.15deg, rgba(20, 26, 29, 0.4) 0%, rgba(20, 26, 29, 0.25) 100%);
	border-radius: 10px;
	margin-top: 10px;
	width: 100%;
}

.dialog-modal-content {
	text-align: center;
}

.error_info {
	text-align: center;
}

.err_head {
	font-size: 26px;
}

/* == RS SLIDER == */

.rs-control {
	display: inline-block;
	z-index: 11;
}

.rs-control .rs-bg-color {
	background-color: #36474f;
}

.rs-control .rs-range-color,
.displayReversed .rs-control .rs-path-color {
	background-color: #0be27b;
}

.rs-control .rs-path-color,
.displayReversed .rs-control .rs-range-color {
	background-color: #f85d57;
}

.rs-control .rs-handle {
	background-color: #607d8b;
	padding: 0px;
	border: 2px solid #607d8b;
}

.rs-control .rs-handle.rs-focus {
	border-color: #607d8b;
}

.rs-control .rs-handle:after {
	border-color: #607d8b;
	background-color: #607d8b;
}

.rs-control .rs-border {
	border: 8px solid #313c46;
}

.rs-bar.rs-start .rs-seperator,
span.rs-bar.rs-transition.rs-end .rs-seperator.rs-border,
.rs-tooltip {
	display: none !important;
}

.rs-inner-container::before {
	content: "";
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	top: 0;
	width: 15px;
	height: 25px;
	background-color: #313d43;
	z-index: 5;
}

.burger_menu,
.pro_name_xp,
.see_results,
.chat_lang_back {
	display: none;
}

.chat_lang_hold {
	-webkit-overflow-scrolling: touch;
	position: absolute;
	bottom: 0;
	display: table;
	table-layout: fixed;
	width: 69%;
}

.betinput.upgrade-mode span {
	color: #141a1d;
	font-size: 14px;
	font-weight: 500;
	line-height: 1.142857142857143;
	background-color: #0ee07b;
	box-shadow: 0 1px 2px 0 #141a1d;
	border-radius: 6px;
	padding: 6px 10px;
	margin-left: 7px;
	cursor: pointer;
}

.messure {
	content: "";
	border-left: 1px solid #141a1d;
	position: absolute;
	top: 0;
	height: 30px;
}

.messure.messure1 {
	left: 18px;
}

.messure.messure2 {
	left: 36px;
}

.messure.messure3 {
	left: 54px;
}

.messure.messure4 {
	left: 72px;
}

.messure.messure5 {
	left: 90px;
}

.messure.messure6 {
	left: 108px;
}

.messure.messure7 {
	left: 126px;
}

.messure.messure8 {
	left: 144px;
}

.messure.messure9 {
	left: 162px;
}

.messure.messure10 {
	left: 180px;
}

.messure.messure11 {
	left: 198px;
}

.messure.messure12 {
	left: 216px;
}

.messure.messure13 {
	left: 234px;
}

.messure.messure14 {
	left: 252px;
}

.container {
	max-width: 1290px;
	padding: 0 60px;
	margin: auto;
}

.pl0 {
	padding-left: 0 !important;
}

/* == SETTINGS == */

.clickableLink {
	color: white;
	text-decoration: underline;
}

.progress-bar {
	position: absolute;
	height: 100%;
	content: "";
	left: 0;
	top: 0;
	background-color: #506976;
	border-radius: 0 9px 9px 0;
}

/* == YOUR AFF STATS == */
.player_tables {
	font-size: 0;
	margin-top: 35px;
	height: calc(100% - 65px);
	overflow-y: auto;
}

.player_tables .table_titles .pl_name:first-child,
.player_tables .pl_no {
	width: 13%;
	text-align: left;
	padding-left: 0;
}

.player_tables .pl_table_data {
	counter-reset: section;
}

.pl_no:before {
	counter-increment: section;
	content: counter(section);
}

.player_tables .table_titles .pl_name:nth-child(2),
.player_tables .pl_data .pl_name {
	width: 47%;
	text-align: left;
	padding-left: 0;
}

.player_tables .table_titles .pl_name:nth-child(2) {
	padding: 10px 0;
}

.player_tables .table_titles .pl_name:nth-child(3),
.player_tables .pl_data .pl_game {
	width: 40%;
	text-align: left;
	padding-left: 0;
}

.player_tables .player_table_content {
	width: 50%;
	display: inline-block;
	vertical-align: top;
	padding: 0;
}

.player_tables .pl_data .pl_name .pl_name_txt {
	padding: 0;
}
.most_wagered {
	color: #adbec6;
	font-weight: 400;
	font-size: 18px;
	line-height: 1.1875;
	text-align: center;
	text-transform: uppercase;
	margin-bottom: 25px;
}

.most_wagered_text {
	color: #607d8b;
	font-size: 14px;
	text-align: center;
	text-transform: none;
	display: inline-block;
	vertical-align: bottom;
	padding-left: 5px;
}

.most_wagered_text:hover {
	color: #0ee07b;
}

.most_wagered_text > span {
	display: inline-block;
	vertical-align: middle;
}

.tables_tab li {
	display: inline-block;
	vertical-align: middle;
	color: #607d8b;
	font-size: 16px;
	line-height: 1.166666666666667;
	padding-left: 10px;
}

.tables_tab li:first-child {
	padding-left: 0;
}

.tables_tab li:before {
	content: "|";
	margin-right: 10px;
}

.tables_tab li:first-child:before {
	display: none;
}

.tables_tab li a:hover,
.tables_tab li.active a {
	color: #0ee07b;
}

.leaderboard_grid_pad,
.bottom_info_grid_pad {
	position: relative;
	padding-top: 72.32%;
}

.leaderboard_grid_pad.not-loggedin,
.bottom_info_grid_pad.not-loggedin {
	padding-top: 79.2%;
}

.player_tables_content {
	padding: 15px;
	height: 100%;
}

.player_tables_content .pl_data:nth-child(odd) {
	background-color: rgba(33, 42, 47, 0.4);
}

.player_tables_content .pl_data:nth-child(even) {
	background-color: transparent;
}

.player_table_content .pl_table_data .pl_data:first-child {
	color: #0ee07b;
	font-weight: 500;
}

.player_table_content .pl_table_data .pl_data:nth-child(2),
.player_table_content .pl_table_data .pl_data:nth-child(3) {
	color: #ffffff;
	font-weight: 500;
}

.player_table_content .pl_table_data .pl_data:nth-child(2) .pl_name_txt,
.player_table_content .pl_table_data .pl_data:nth-child(3) .pl_name_txt {
	font-weight: 500;
	max-height: 16px;
}

.player_table_content .pl_name_txt,
.player_table_content .pl_data {
	font-weight: 100;
}

.player_table_content .pl_link {
	padding: 10px 0;
}

.player_table_content.player_table_content_width {
	width: 100%;
}

.chat_rooms.chat_lang_show_only_chat .chat_link {
	color: #adbec6;
}

/* === CHAT COLOR BARS === */

.messages .colorbar {
	position: absolute;
	top: 0;
	left: -7px;
	height: 100%;
	width: 3px;
	background-color: #ffffff;
}

.chat_msg .colorbar.rain {
	background-color: cornflowerblue;
}

.chat_msg .colorbar.admin {
	background-color: #ff5a5a;
}

.chat_msg .colorbar.support {
	background-color: #00e9ff;
}

.chat_msg .colorbar.developer {
	background-color: orange;
}

.chat_msg .colorbar.moderator {
	background-color: #fff275;
}

.chat_msg .colorbar.user {
	display: none;
}

.messages .colorbar.mute {
	background-color: #11d87a;
}

.chat_msg .colorbar.info {
	background-color: #adbec6;
}

.chat_msg .colorbar.bonus {
	background-color: #fff275;
}

/* === CHAT TOP GRADIENT === */

.chatting_chat_msgs .top-gradient {
	pointer-events: none;
	flex: none;
	height: 8vh;
	width: 100%;
	position: absolute;
	left: 0;
	top: 0;
	z-index: 2;
	background-image: linear-gradient(rgba(43, 62, 70) 0%, rgba(43, 62, 70, 0));
}

/* === DESIGN CHANGER ON TOP === */

.group_center {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-flow: column;
}
.group_center b {
	font-size: 20px;
	color: white;
}

.item_gfx {
	width: 100%;
	height: 100%;
	background-size: 66.66%;
	background-position: center center;
	background-repeat: no-repeat;
	border-top-width: 2px;
	border-top-style: solid;
}
.item_gfx_holder {
	background: radial-gradient(ellipse at center, #01020230 0%, #0304045c 100%);
	margin: 5px;
}
/* .item_gfx_holder {
    background: radial-gradient(ellipse at center, #ffffff00 0%, #00000038 100%);
    margin: 1px;
} */
.item_gfx_4 {
	background-size: 55%;
}
.item_gfx_1 {
	border-top-width: 0px;
}

@-webkit-keyframes pulse {
	0% {
		-webkit-text-shadow: 0 0 0 rgb(14, 224, 123);
	}
	70% {
		-webkit-text-shadow: 0 0 10px rgb(14, 224, 123);
	}
	100% {
		-webkit-text-shadow: 0 0 0 rgb(14, 224, 123);
	}
}
@keyframes pulse {
	0% {
		-moz-text-shadow: 0 0 0 rgb(14, 224, 123);
		text-shadow: 0 0 0 rgb(14, 224, 123);
	}
	70% {
		-moz-text-shadow: 0 0 10px rgb(14, 224, 123);
		text-shadow: 0 0 10px rgb(14, 224, 123);
	}
	100% {
		-moz-text-shadow: 0 0 0 rgb(14, 224, 123);
		text-shadow: 0 0 0 rgb(14, 224, 123);
	}
}

/* Open tab: 52px | One un open tab: 10.25% */
/* === END OF CHANNELS SELECTOR REFACTORING === */
/* === ENF OF ONLINE ICON PULSE EFFECT === */
/* === SIGNUP PAGE === */
.hiddenCaptcha {
	visibility: hidden;
}
.captchaPopup {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 2;
}
/* === END OF SIGNUP PAGE === */
/* === INFO BOX ON HOME PAGE === */
.bottom_info_grid_pad {
	position: relative;
	padding-top: 15.6%;
}

.bottom_info_grid_pad.less {
	padding-top: 0;
}
.bottom_info_grid_pad.more {
	padding-top: 0;
}
.bottom_info_grid_pad.not-loggedin {
	padding-top: 79.2%;
}
.italic {
	font-style: italic;
}
.bottom_info_grid_pad :first-child {
	margin-top: 10px;
	padding: 0;
	color: white;
}
.bottom_info_grid_pad .player_tables_content {
	padding: 10px;
	height: initial;
}
.bottom_info_grid_pad .home_info:first-child :first-child {
	margin-top: 0;
}
.home_info > p {
	margin-top: 5px;
}
.home_info h1,
.home_info h2,
.home_info h3,
.home_info h4,
.home_info h5,
.home_info h6 {
	font-size: 20px;
}
.hideMore {
	display: none;
}
.showMore {
	display: initial;
}
/* === END: INFO BOX ON HOME PAGE */
/* === START: TOPBAR LOGOUT/LOGIN BUTTON === */
.logout.topbar_pro_pic {
	position: absolute;
	right: 0;
	width: 65px;
	top: 50%;
	transform: translateY(-50%);
}
.logout.topbar_pro_pic img {
	width: 55px;
	height: 55px;
	padding: 1px;
	border: 1px solid #3d4f59;
	border-radius: 10px;
	transition: border 0.5s;
}
.logout.topbar_pro_pic:hover img {
	border-color: #0ee07b;
}
.menu_links {
	position: relative;
	overflow: hidden;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.field_group input:-webkit-autofill,
.field_group input:-webkit-autofill:hover,
.field_group input:-webkit-autofill:focus,
.field_group textarea:-webkit-autofill,
.field_group textarea:-webkit-autofill:hover,
.field_group textarea:-webkit-autofill:focus,
.field_group select:-webkit-autofill,
.field_group select:-webkit-autofill:hover,
.field_group select:-webkit-autofill:focus,
.field_group input:-internal-autofill-selected,
.field_group input:-internal-autofill-selected:hover,
.field_group input:-internal-autofill-selected:focus,
.field_group textarea:-internal-autofill-selected,
.field_group textarea:-internal-autofill-selected:hover,
.field_group textarea:-internal-autofill-selected:focus,
.field_group select:-internal-autofill-selected,
.field_group select:-internal-autofill-selected:hover,
.field_group select:-internal-autofill-selected:focus {
	background-color: #29363d !important;
}
.password_show_toggle {
	font-size: 15px;
	position: absolute;
	right: 20px;
	top: 20px;
	z-index: 1;
}
.password_show_toggle:hover {
	cursor: pointer;
}
/* === END: TOPBAR LOGOUT/LOGIN BUTTON === */
.items {
	flex-direction: row;
	display: -webkit-flex;
	/* Safari */
	-webkit-flex-direction: row; /* Safari 6.1+ */
	align-items: center;
	display: flex;
}
body {
	height: 100%;
	width: 100%;
	font-stretch: normal;
	font-weight: 400;
}
#countdown svg circle {
	animation-name: countdown;
	animation-duration: 60s;
	animation-timing-function: linear;
	animation-iteration-count: infinite;
	animation-direction: forwards;
}
@-webkit-keyframes spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(2520deg);
	}
}
#jackpot-icon-ctn {
	margin-left: auto;
	margin-right: auto;
	display: block;
	height: 32px;
	width: 29px;
}
.jp-game-jackpot-react-reel__group .jp-game-jackpot-react-reel__number {
	font-size: 28px;
	color: #fff275;
	height: 28px;
}
.jp-game-jackpot-react-reel__reel {
	height: 28px;
	display: flex;
	align-items: flex-end;
	overflow-y: hidden;
}
.jp-game-jackpot:before {
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 66px 0 0 50px;
	border-color: transparent transparent transparent #232c31;
	position: absolute;
	left: -5px;
	top: 5px;
	content: "";
}
#user-wager-stats-wrap {
	position: fixed;
	opacity: 0;
	z-index: 3;
}
#user-wager-stats {
	width: 240px;
	height: 85px;
	background-color: rgba(46, 60, 67, 0.82);
	color: white;
	flex-direction: column;
	display: flex;
	border-radius: 10px;
	align-items: center;
}
#user-wager-stats-name {
	font-size: 14px;
	flex-direction: row;
	flex: 1;
	display: flex;
	align-items: center;
	flex-grow: 1;
	padding: 10px 0px 0px 10px;
}
#user-wager-stats-history {
	flex-direction: row;
	flex: 3;
	display: flex;
	align-items: center;
	width: 100%;
}
#user-wager-stats-history div {
	flex: 1;
	flex-direction: column;
	display: flex;
	align-items: center;
}
#user-wager-stats-history div span {
	font-size: 13px;
}
#user-wager-stats-history div span:first-child {
	font-size: 11px;
	padding-bottom: 4px;
}
#user-wager-stats i {
	padding-right: 5px;
}
#user-wager-stats .flag_img {
	float: right;
	width: 26px;
	height: 16px;
}
/* === END: TOPBAR LOGOUT/LOGIN BUTTON === */
/* === START: CHAT TABS REFACTORING === */
.chat_lang .tab {
	display: table-cell;
	cursor: pointer;
	padding: 4px;
	text-align: center;
	border-radius: 5px 5px 0 0;
	color: #656565;
	background: #27363d;
	transition: all 0.4s ease;
	position: relative;
}
.chat_lang .tab:before {
	content: "";
	height: 15px;
	width: 1.5px;
	margin-top: 2.5px;
	background: #344147;
	position: absolute;
	left: 0;
}
.chat_lang .tab:hover:before,
.chat_lang .tab:first-child:before,
.chat_lang .tab.selectedChannel:before,
.chat_lang .tab.selectedChannel + .tab:before,
.chat_lang .tab:hover + .tab:before {
	content: initial;
}
.chat_lang .tab.selectedChannel {
	color: #64757c;
	background: #2b3e46;
	width: 52px;
	display: table-cell !important;
}
.chat_lang .tab .lan_close {
	position: absolute;
	display: block;
	left: 50%;
	transform: translateX(-50%);
	height: 15px;
	top: -30%;
}
.chat_lang .tab .lan_close:before,
.chat_lang .tab .lan_close:after {
	background-color: #27363d;
	left: 50%;
	width: 10px;
	float: right;
}
.chat_lang .tab:hover .lan_close:before,
.chat_lang .tab:hover .lan_close:after,
.chat_lang .tab.selectedChannel .lan_close:before,
.chat_lang .tab.selectedChannel .lan_close:after {
	background-color: #adadad;
}
.chat_lang .tab .lan_close:before {
	transform: translateX(-50%) rotate(45deg);
}
.chat_lang .tab .lan_close:after {
	transform: translateX(-50%) rotate(-45deg);
}
.chat_lang .tab > * {
	display: block;
	line-height: 1.33;
}
.tab.selectedChannel .lan_close.hiddenX:before,
.tab.selectedChannel .lan_close.hiddenX:after,
.chat_lang .tab:hover .lan_close.hiddenX:after,
.chat_lang .tab:hover .lan_close.hiddenX:before {
	background-color: transparent;
	box-shadow: none;
}
.tab .chan_img {
	float: initial;
	width: 23px;
	height: 18px;
	margin-top: initial;
	opacity: 0.5;
	transition: opacity 0.4s ease;
	position: relative;
	left: 50%;
	transform: translateX(-50%);
}
.tab.selectedChannel .chan_img,
.tab:hover .chan_img {
	opacity: 1;
}
.tab.selectedChannel:not(.notClosable) .chan_img {
	left: 40%;
}
.tab:not(.selectedChannel):hover .lan_close,
.lan_close:hover {
	position: absolute;
	margin-bottom: 10px;
}
.tab.selectedChannel .lan_close {
	top: 50% !important;
	transform: translateX(-50%) translateY(-50%);
	left: 85%;
	margin-top: 0;
	width: 16.5px;
}
.chat_msg.msg-tag-checkmarked .chat_user_name,
.chat_msg.msg-tag-checkmarked .icon-gammdom-symbol,
.chat_msg.msg-tag-checkmarked .chat_user_colen {
	color: #53a4ff;
}
.chat_msg.msg-tag-checkmarked .colorbar.user {
	display: inline-block;
	background-color: #53a4ff;
}
@keyframes fullWidth {
	from {
		height: 0;
		width: 0;
		opacity: 0.5;
		margin: 0 auto;
	}
	to {
		height: 121px;
		margin: 0 auto;
		width: 100%;
		opacity: 1;
	}
}
.pointer {
	cursor: pointer;
}
