@mixin skeletonCss {
	background: #556067;
	overflow: hidden;
	border-radius: 7px;
	position: relative;

	&::after {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		transform: translateX(-100%);
		background-image: linear-gradient(
			90deg,
			rgba(#757e84, 0) 0,
			rgba(#757e84, 0.2) 20%,
			rgba(#757e84, 0.5) 60%,
			rgba(#757e84, 0)
		);
		animation: shimmer 2s infinite;
		content: "";
	}
}

@keyframes shimmer {
	100% {
		transform: translateX(100%);
	}
}

.eg {
	&__game {
		width: 17%;
		margin-right: 3.75%;
		height: 240px;
		border-radius: 6px;
		background: #2f3d45;
		margin-bottom: 25px;
		cursor: pointer;
		filter: drop-shadow(0 2px 0 #1e282c);
		position: relative;
		top: 0;
		transition:
			top 0.2s linear,
			background 0.2s linear;
		display: flex;
		flex-direction: column;

		&:nth-child(5n) {
			margin-right: 0;
		}

		&:not(.eg__game--disabled):hover {
			top: -5px;
			background: #3b505c;
		}

		&--disabled {
			cursor: not-allowed;
			filter: brightness(0.3);
		}

		&--skeleton {
			.eg__text-wrap {
				width: 100%;
			}
			.eg__game-pic {
				@include skeletonCss();
				width: calc(100% - 40px);
				height: 80%;
			}

			.eg__game-title {
				@include skeletonCss();
				height: 17px;
				width: 80%;
			}
			.eg__game-descr {
				@include skeletonCss();
				height: 15px;
				width: 60%;
			}
			// .eg__game-licon {
			// 	color: #d8d8d8;
			// }
			// .eg__like-count {
			// 	background: #d8d8d8;
			// 	width: 15px;
			// 	height: 15px;
			// 	border-radius: 99999px;
			// }
		}
	}

	&__game-live {
		display: none;
		background-color: #d94242;
		border-radius: 3px;
		width: 53px;
		height: 33px;
		position: absolute;
		bottom: 5px;
		right: 5px;
		color: white;
		text-align: center;
		padding: 8px 11px;

		@at-root .eg__game--live & {
			display: inline-block;
		}
	}

	&__game-pic-wrap {
		position: relative;
		height: 150px;
		display: flex;
		justify-content: center;
		align-items: center;
		filter: drop-shadow(0 2px 0 rgba(30, 40, 44, 0.5));
	}
	&__game-pic {
		width: 100%;
		height: 100%;
		background-repeat: no-repeat;
		background-position: center;
		border-radius: 6px 6px 0 0;
		background-size: cover;
		opacity: 1;
		transition: opacity 0.2s linear;

		@at-root .eg__game:not(.eg__game--disabled):hover & {
			opacity: 0.6;
		}
	}
	&__iplay {
		@include posHorVertAlign;
		position: absolute;
		opacity: 0;
		font-size: 43px;
		color: white;
		transition: opacity 0.2s linear;

		@at-root .eg__game:not(.eg__game--disabled):hover & {
			opacity: 1;
		}
	}
	&__game-info {
		padding: 0 20px;
		display: flex;
		align-items: center;
		flex: 1;
	}
	&__game-title {
		color: rgb(174, 191, 199);
		margin-bottom: 6px;
		font-weight: 500;
		padding-right: 5px;
	}
	&__game-descr {
		color: rgba(174, 191, 199, 0.5);
	}
	&__like-count {
		color: rgb(174, 191, 199);
	}
	&__game-lwrap {
		margin-left: auto;
		text-align: center;
	}
	&__game-licon {
		font-size: 16px;
		color: #23d160;
	}
	&__like-count {
		color: rgb(174, 191, 199);
		font-size: 14px;
		font-weight: 500;
	}
}
