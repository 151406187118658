.loading-header {
	position: fixed;
	left: 50%;
	top: 30%;
	transform: translateX(-50%);
}
@media only screen and (max-width: 767px) {
	.loading-header {
		top: 20%;
		text-align: center;
		left: 0%;
		transform: translateX(0%);
	}
}
.loading-header h1 {
	margin: 0 auto;
}
.cube-folding {
	width: 50px;
	height: 50px;
	display: inline-block;
	font-size: 0;
	transform: rotate(45deg);
}
.cube-folding span {
	position: relative;
	display: inline-block;
	width: 25px;
	height: 25px;
	transform: scale(1.1);
}
.cube-folding span::before {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	display: block;
	width: 25px;
	height: 25px;
	background-color: white;
	transform-origin: 100%, 100%;
	animation: folding 2.5s infinite linear both;
}
.cube-folding .leaf-2 {
	transform: rotateZ(90deg) scale(1.1);
}
.cube-folding .leaf-2::before {
	background-color: #f2f2f2;
	animation-delay: 0.3s;
}
.cube-folding .leaf-3 {
	transform: rotateZ(270deg) scale(1.1);
}
.cube-folding .leaf-3::before {
	background-color: #f2f2f2;
	animation-delay: 0.9s;
}
.cube-folding .leaf-4 {
	transform: rotateZ(180deg) scale(1.1);
}
.cube-folding .leaf-4::before {
	background-color: #e6e6e6;
	animation-delay: 0.6s;
}
.cube-wrapper {
	position: fixed;
	left: 50%;
	top: 50%;
	width: 100px;
	height: 100px;
	margin-top: 50px;
	margin-left: -50px;
	text-align: center;
}
.cube-wrapper--local {
	position: absolute;
	transform: translate(-50%, -50%);
	margin: 0;
}
.cube-wrapper:after {
	content: "";
	position: absolute;
	left: 0;
	right: 0;
	bottom: -20px;
	width: 90px;
	height: 6px;
	margin: auto;
	background-color: rgba(0, 0, 0, 0.1);
	filter: blur(2px);
	border-radius: 100%;
	animation: shadow 0.5s ease infinite alternate;
	z-index: 1;
}
.cube-wrapper .loading {
	position: relative;
	display: block;
	top: 25px;
	font-size: 12px;
	letter-spacing: 0.1em;
	color: white;
	animation: text 0.5s ease infinite alternate;
	z-index: 2;
}
.cube-wrapper .loading--main {
	font-size: 20px;
	color: #eeeeee;
	font-weight: 500;
}
@keyframes folding {
	0%,
	10% {
		transform: perspective(140px) rotateX(-180deg);
		opacity: 0;
	}
	25%,
	75% {
		transform: perspective(140px) rotateX(0deg);
		opacity: 1;
	}
	90%,
	100% {
		transform: perspective(140px) rotateY(180deg);
		opacity: 0;
	}
}
@keyframes text {
	100% {
		top: 35px;
	}
}
@keyframes shadow {
	100% {
		bottom: -18px;
		width: 100px;
	}
}
@keyframes moving {
	0% {
		background-position: 0% 0%;
	}
	100% {
		background-position: 0% 100%;
	}
}
#chart-outer-container {
	padding: 20px 20px 10px 0;
}
@media only screen and (max-width: 767px) {
	#chart-container {
		bottom: 0;
		left: 0;
		right: 0;
	}
}
#chart-container #chart-outer-container {
	padding: 20px 10px 10px 0;
	position: relative;
	height: 100%;
	width: 100%;
}
#chart-container #chart-inner-container {
	position: relative;
	height: 100%;
	width: 100%;
}
#chart-container .max-profit {
	position: absolute;
	top: 0;
	left: 10%;
	font-size: 12px;
	font-weight: 500;
	color: #b5babb;
	display: inherit;
}
#chart-container #text-display-container {
	width: 100%;
	height: 100%;
	font-size: 100%;
	text-align: center;
}
#chart-container #text-display-container.starting {
	color: #b5babb;
}
#chart-container #text-display-container.starting span {
	position: absolute;
	top: 50%;
	right: 0;
	left: 0;
}
#chart-container #text-display-container.connecting {
	color: #b5babb;
}
#chart-container #text-display-container.in-progress span {
	position: absolute;
	top: 50%;
	right: 0;
	left: 0;
}
#chart-container #text-display-container.ended {
	color: #da4939;
}
#chart-container #text-display-container.ended span.busted {
	position: absolute;
	top: 50%;
	right: 0;
	left: 0;
	line-height: 100%;
	-webkit-transform: translateY(-100%);
	-moz-transform: translateY(-100%);
	-ms-transform: translateY(-100%);
	-o-transform: translateY(-100%);
	transform: translateY(-100%);
}
#chart-container #text-display-container.ended span.at {
	position: absolute;
	top: 50%;
	right: 0;
	left: 0;
	line-height: 100%;
	-webkit-transform: translateY(0);
	-moz-transform: translateY(0);
	-ms-transform: translateY(0);
	-o-transform: translateY(0);
	transform: translateY(0);
}
#chart-container #text-display-container span {
	font-size: inherit;
}
#chart-container .connection-state {
	position: absolute;
	top: 50%;
	left: 50%;
	text-align: center;
	font-size: 40px;
	color: #ffffff;
	transform: translate(-50%, -50%);
}
.daily-rewards-modal-content,
.gameinfo-modal-content,
.tradeoffers-modal-content,
.userinfo-modal-content,
.tradeup-provablyfair-modal-content,
.deposit-modal-content {
	overflow: auto;
	-webkit-overflow-scrolling: touch;
	border-radius: 3px;
	-moz-background-clip: padding;
	-webkit-background-clip: padding-box;
	background-clip: padding-box;
	outline: none;
	padding: 25px;
	margin: 0 auto;
}
.daily-rewards-modal-content > div,
.gameinfo-modal-content > div,
.tradeoffers-modal-content > div,
.userinfo-modal-content > div,
.tradeup-provablyfair-modal-content > div,
.deposit-modal-content > div {
	position: relative;
	width: 100%;
}
.daily-rewards-modal-content .popup-content,
.gameinfo-modal-content .popup-content,
.tradeoffers-modal-content .popup-content,
.userinfo-modal-content .popup-content,
.tradeup-provablyfair-modal-content .popup-content,
.deposit-modal-content .popup-content {
	width: 100%;
	height: 100%;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
	padding: 20px 0px;
}
.daily-rewards-modal-content .modal-close-button,
.gameinfo-modal-content .modal-close-button,
.tradeoffers-modal-content .modal-close-button,
.userinfo-modal-content .modal-close-button,
.tradeup-provablyfair-modal-content .modal-close-button,
.deposit-modal-content .modal-close-button {
	position: absolute;
	right: 10px;
	top: 10px;
	line-height: 1ex;
	font-size: 30px;
	z-index: 1;
	margin-top: 2px;
	color: #0ee07b;
}
.daily-rewards-modal-content .modal-close-button:hover,
.gameinfo-modal-content .modal-close-button:hover,
.tradeoffers-modal-content .modal-close-button:hover,
.userinfo-modal-content .modal-close-button:hover,
.tradeup-provablyfair-modal-content .modal-close-button:hover,
.deposit-modal-content .modal-close-button:hover {
	color: #0a7843;
}
.daily-rewards-modal-content .modal-close-button > div,
.gameinfo-modal-content .modal-close-button > div,
.tradeoffers-modal-content .modal-close-button > div,
.userinfo-modal-content .modal-close-button > div,
.tradeup-provablyfair-modal-content .modal-close-button > div,
.deposit-modal-content .modal-close-button > div {
	cursor: pointer;
}
.daily-rewards-modal-content .modal-close-button > div:after,
.gameinfo-modal-content .modal-close-button > div:after,
.tradeoffers-modal-content .modal-close-button > div:after,
.userinfo-modal-content .modal-close-button > div:after,
.tradeup-provablyfair-modal-content .modal-close-button > div:after,
.deposit-modal-content .modal-close-button > div:after {
	content: "\D7";
}
.playerlist-hidebutton {
	z-index: 99;
	right: 1px;
	top: 13px;
}
.playerlist-animation-enter {
	transform: translate3d(100%, 0, 0);
	opacity: 0;
}
.playerlist-animation-enter.playerlist-animation-enter-active {
	transform: translate3d(0, 0, 0);
	opacity: 1;
	transition: 0.5s;
}
.playerlist-animation-exit {
	position: absolute;
	opacity: 1;
	transform: translate3d(0, 0, 0);
}
.playerlist-animation-exit.playerlist-animation-exit-active {
	transform: translate3d(100%, 0, 0);
	opacity: 0;
	transition: 0.5s;
}
