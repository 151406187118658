$notification-border-radius-pixel: 9px;

@mixin notification-message-body() {
	overflow: visible !important;
	border-radius: $notification-border-radius-pixel;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
	background: #1d2329 !important;
}
.notification-message-body-mobile {
	@include notification-message-body();
	width: calc(100% - 32px) !important;
	margin: auto;
	margin-bottom: 10px !important;
}

.notification-message-body-desktop {
	@include notification-message-body();
}
.notification-message-progress {
	margin-left: $notification-border-radius-pixel;
	margin-right: $notification-border-radius-pixel;
	width: calc(100% - (2 * #{$notification-border-radius-pixel})) !important;
	height: 2px !important;
	background-color: #00ff86 !important;
}

#notification-message-container-desktop {
	width: 369px;
}

#notification-message-container-mobile {
	margin-top: 64px;
	width: calc(100% - 32px) !important;
}
.Toastify__toast-body {
	max-width: 100% !important;
}
.Toastify__toast-container--top-right {
	padding: 0px !important;
	width: 350px !important;
	top: 75px !important;
	right: 25px !important;
}

.Toastify__toast-container--top-center {
	margin-top: 64px !important;
	width: 100% !important;
}

.Toastify__toast-container--bottom-left {
	@media only screen and (max-width: 480px) {
		padding: 0 12px 12px 12px !important;

		.Toastify__toast {
			margin-top: 12px;
		}
	}
}

.Toastify__toast-container {
	@media only screen and (max-width: 480px) {
		bottom: 70px !important;
	}
}
