@mixin posHorAlign {
	transform: translateX(-50%);
	left: 50%;
}

@mixin posVertAlign {
	transform: translateY(-50%);
	top: 50%;
}

@mixin posHorVertAlign {
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
