#router-container {
	width: inherit;
	height: inherit;
	background: #0a1119;
}

@keyframes shake {
	0% {
		transform: translate(1px, 1px) rotate(0deg);
	}

	10% {
		transform: translate(-1px, -2px) rotate(-1deg);
	}

	20% {
		transform: translate(-3px, 0px) rotate(1deg);
	}

	30% {
		transform: translate(3px, 2px) rotate(0deg);
	}

	40% {
		transform: translate(1px, -1px) rotate(1deg);
	}

	50% {
		transform: translate(-1px, 2px) rotate(-1deg);
	}

	60% {
		transform: translate(-3px, 1px) rotate(0deg);
	}

	70% {
		transform: translate(3px, 1px) rotate(-1deg);
	}

	80% {
		transform: translate(-1px, -1px) rotate(1deg);
	}

	90% {
		transform: translate(1px, 2px) rotate(0deg);
	}

	100% {
		transform: translate(1px, -2px) rotate(-1deg);
	}
}

$gamdomColor: #23d160;
$gamdomLightColor: #15ff8e;

html {
	-webkit-text-size-adjust: 100%;
	height: 100%;
}

body {
	overflow-x: hidden;
	&.ReactModal__Body--open {
		overflow: hidden;
	}
}

* {
	margin: 0;
	padding: 0;
	vertical-align: top; // TODO: this should definitely be removed from here
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

ul,
li {
	list-style: none;
}

i {
	font-style: normal;
}

img {
	max-width: 100%;
}

a,
button {
	cursor: pointer;
	text-decoration: none;
	color: inherit;
	transition: all 0.4s ease;
}

a:active {
	background-color: transparent;
}

select,
map,
area,
a,
button {
	border: 0px;
	outline: none;
}

img {
	border-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: normal;
}

input[type="text"],
input[type="password"],
textarea {
	text-align: left;
	background: transparent;
	cursor: auto;
}

input,
textarea,
select {
	border: 0;
	outline: none;
	line-height: normal;
	-webkit-appearance: none;
	border-radius: 0;
}

textarea {
	resize: none;
}

.image {
	position: relative;
	display: block;
}

.overflow-hidden {
	overflow: hidden;
}

@keyframes rotateSpinner {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.hide {
	display: none;
}

.icon-gammdom-symbol {
	vertical-align: baseline;
}

button.disabled {
	cursor: not-allowed;
	opacity: 0.3;
	pointer-events: unset;
}

@-webkit-keyframes progress-bar-stripes {
	from {
		background-position: 0 0;
	}

	to {
		background-position: 40px 0;
	}
}

@-moz-keyframes progress-bar-stripes {
	from {
		background-position: 0 0;
	}

	to {
		background-position: 40px 0;
	}
}

@keyframes progress-bar-stripes {
	from {
		background-position: 0 0;
	}

	to {
		background-position: 40px 0;
	}
}

.Toastify__toast-container {
	z-index: 99999999 !important;
}

.Toastify__toast {
	border-radius: 4px !important;
	display: flex !important;
	align-items: center !important;
}

.Toastify__toast--warning {
	background: #e9cb52 !important;
}

.Toastify__toast--error {
	background: #dc4362 !important;
}

.Toastify__toast--success {
	background: #44c771 !important;
}

.koth__modal-overlay {
	background: red;
	width: 100vw;
	height: 100vw;
	background-color: rgba(00, 00, 00, 0.15) !important;
}

.landing__page_content--logged-in {
	.crash_grid {
		&:nth-child(1) {
			width: 100%;
			height: 100%;
		}

		&:nth-child(2) {
			display: none !important;
		}
	}
}

.koth-modal {
	background-color: rgba(0, 0, 0, 0.75) !important;

	&-inside {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
		max-width: 100% !important;
	}
}

.gt {
	color: $gamdomColor;

	&--hv {
		&:hover {
			cursor: pointer;
			color: $gamdomLightColor;
		}
	}

	&--fw {
		font-weight: 700;
	}
}

.c-text {
	color: #23d160;
}

.b-text {
	font-weight: 500;
}

.img-resp {
	max-height: 100%;
	max-width: 100%;
}

.clickable {
	cursor: pointer;
}

.currency-amount {
	display: inline;
	white-space: nowrap;
}

.jackpot-counter-icon {
	font-size: 24px;
	color: #1e2e34;
	position: absolute;
	left: 14px;
	top: 4.5px;
}

.MuiSelect-selectMenu {
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
}

.MuiPopover-root {
	z-index: 5000 !important;

	.MuiMenu-paper {
		background: #12191d;
		box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
		border-radius: 3px;
		color: #bbc9d0;

		.MuiMenuItem-root {
			margin: 0;
		}

		.Mui-selected:hover {
			background: rgba(47, 61, 69, 0.4);
			border-radius: 4px;
		}
	}

	.MuiPaper-root {
		margin-top: 5px;
	}
}
