@media (max-width: 1920px) {
	ul.messages li {
		font-size: 15px;
	}
	.btn {
		font-size: 17px;
	}
}

@media (max-width: 1800px) {
	.aff_col {
		padding: 27px 25px;
	}
	.aff_col .title,
	.status_today .title {
		font-size: 22px;
	}
	.leaderboard_grid_pad {
		padding-top: 87%;
	}
	.leaderboard_grid_pad.not-loggedin {
		padding-top: 98%;
	}
	.not-loggedin .medium_grid_pad {
		padding-top: 92%;
	}
	.medium_grid_pad {
		padding-top: 98% !important;
	}
	.twitter_grid_pad {
		padding-top: 182%;
	}
	.twitter_grid_pad.not-loggedin {
		padding-top: 92%;
	}
	.status_grid_pad {
		padding-top: 90%;
	}
	.status_today {
		padding: 20px;
	}
	.graph_grid_pad {
		padding-top: 45%;
	}
	.information .icon {
		font-size: 36px;
	}
	.user_stats .user_xp {
		padding: 10px 20px;
	}
}

@media (max-width: 1600px) {
	.table_titles .pl_name:first-child,
	.pl_name {
		width: 46%;
	}
	.pl_game,
	.pl_bet,
	.pl_profit {
		width: 18%;
	}
	.pl_count .icon {
		font-size: 20px;
	}
	.player_count {
		font-size: 18px;
		padding-left: 5px;
	}
	.question_mark {
		width: 24px;
		height: 24px;
		font-size: 18px;
	}
	.jackpot .text,
	.chat_user_name {
		font-size: 15px;
	}
	.game_history .icon {
		font-size: 24px;
	}
	.player_table .player_table_head {
		padding-bottom: 10px;
	}

	.btn,
	.chat_lan {
		font-size: 16px;
	}
	.pro_digit > span:last-child {
		font-size: 16px;
	}
	.card {
		padding-right: 6px;
	}

	.counting {
		right: 25px;
	}

	.aff_col .title,
	.status_today .title {
		font-size: 20px;
	}
	.grid_name {
		padding-top: 15px;
	}
	.g_img img {
		height: 63px;
	}
	.xp {
		font-size: 16px;
	}
	.twitt_head {
		font-size: 18px;
	}
	.leaderboard_grid_pad {
		padding-top: 90%;
	}
	.not-loggedin .medium_grid_pad {
		padding-top: 98% !important;
	}
	.status_grid_pad {
		padding-top: 95%;
	}
	.graph_grid_pad {
		padding-top: 47.5%;
	}
	.information .icon {
		font-size: 34px;
	}
	.faq_single .f_ques {
		font-size: 18px;
	}
	.heading {
		font-size: 17px;
	}
	.most_wagered {
		font-size: 17px;
	}
	.twitter_grid_pad.not-loggedin {
		padding-top: 99%;
	}
}

@media (max-width: 1500px) {
	.player_count {
		font-size: 15px;
		padding-left: 0;
	}

	.jackpot {
		margin-left: 15px;
	}
	.pl_count .pl_onli,
	.field_group input,
	.c_select select,
	.chat_lan,
	.inline_input {
		font-size: 15px;
	}
	.question_mark {
		width: 22px;
		height: 22px;
		font-size: 17px;
		margin-bottom: 7px;
	}
	#logoutDropdown {
		font-size: 13px;
		padding: 20px 0 10px;
	}

	.page_header {
		min-height: 75px;
	}
	.pl_name_txt {
		padding-left: 7px;
	}
	.jackpot:after {
		font-size: 21px;
	}
	.check_label:before {
		width: 14px;
		height: 14px;
	}
	.check_label:after {
		left: 6px;
		top: 2px;
	}
	.check_label {
		padding-left: 28px;
	}
	.inline_input {
		width: 70px;
	}
	.pro_digit > span:last-child {
		font-size: 14px;
		margin-bottom: 0;
	}
	.card {
		padding-right: 4px;
	}
	.user_stats .user_levels {
		height: 90px;
	}
	.xp {
		font-size: 15px;
	}
	.aff_col .title,
	.status_today .title {
		font-size: 18px;
	}
	.home .btn {
		width: 175px;
	}
	.g_img img {
		height: 52px;
	}
	.graph_grid_pad {
		padding-top: 53.4%;
	}
	.status_grid_pad {
		padding-top: 107%;
	}
	.information .icon {
		font-size: 32px;
	}
	.p30 {
		padding: 25px;
	}
	.faqs {
		margin-top: 15px;
	}
	.sidebar_link {
		font-size: 15px;
	}
	.container {
		padding-left: 40px;
		padding-right: 40px;
	}
}

@media (max-width: 1366px) {
	.chat_lang {
		height: 30px;
	}
	.chat_link {
		margin-right: 0px;
	}
	.jackpot {
		min-width: 0;
	}
	.most_wagered {
		font-size: 16px;
	}
	.rainDropdown .tipRain {
		padding: 3px 11px;
	}
	.chat_lan_li {
		margin-top: 20px;
	}
	.page_header {
		padding: 20px;
		text-align: center;
	}

	ul.messages li {
		font-size: 14px;
	}
	.col_full,
	.col_half,
	.col_one_third,
	.col_two_third,
	.col_one_fourth {
		padding: 0 10px;
	}
	.col_row {
		margin: 0 -10px;
	}
	.game_controls {
		position: relative;
		padding-bottom: 85px;
	}
	.ptb0 {
		padding-top: 0;
		padding-bottom: 0;
	}
	.home .page_header,
	.profile .page_header {
		text-align: left;
	}
	.home .btn {
		padding: 13px 20px;
	}
	.padd_abs,
	.biggest_grid {
		padding: 10px;
	}
	.col_grids {
		margin-top: -10px;
	}
	.leaderboard_grid_pad {
		padding-top: 95.6%;
	}
	.leaderboard_grid_pad.not-loggedin {
		padding-top: 207%;
	}
	.biggest_grid .title {
		font-size: 16px;
	}
	.twitter_feed {
		padding: 20px;
	}
	.not-loggedin .medium_grid_pad {
		padding-top: 110% !important;
	}
	.medium_grid_pad {
		padding-top: 106% !important;
	}
	.twitt_head {
		font-size: 16px;
	}
	.twitter_grid_pad {
		padding-top: 212%;
	}
	.twitter_grid_pad.not-loggedin {
		padding-top: 110%;
	}
	.aff_col {
		padding: 20px;
	}
	.status_grid_pad {
		padding-top: 125%;
	}
	.graph_grid_pad {
		padding-top: 62.25%;
	}
	.informations {
		padding: 30px 15px;
	}
	.user_stats_graph {
		padding: 30px;
	}
	.sidebar_link {
		margin-top: 0;
	}
	.faq_single .f_ques,
	.sub_heading {
		font-size: 17px;
	}
	.sub_heading {
		margin-top: 15px;
	}
	.f_ques:before {
		font-size: 23px;
	}
	.faq_description,
	.editor ul li,
	.editor ol li,
	.description {
		font-size: 15px;
	}
	.p30 {
		padding: 20px;
	}
	.faq .search {
		max-width: 250px;
	}
	.ReactModal__Content.dialog-modal-content .ui-dialog .dialogText,
	.ReactModal__Content.dialog-modal-content .form-group .lbl_head,
	.ReactModal__Content.dialog-modal-content .form-group .form-control {
		font-size: 18px;
	}
	.ReactModal__Content.dialog-modal-content .form-group .input_mark .icon {
		font-size: 21px;
	}
	.container {
		padding-left: 20px;
		padding-right: 20px;
	}
	.pt30 {
		padding-top: 20px;
	}
}

@media (max-width: 1200px) {
	.icon-angle-double-left:before {
		content: "\F101";
	}
	.icon-angle-double-right:before {
		content: "\F100";
	}

	#logoutDropdown {
		font-size: 12px;
	}
}
@media (min-width: 1024px) {
	.leaderboard_grid_pad.not-loggedin {
		padding-top: 165%;
	}
}

@media (min-width: 1366px) {
	.leaderboard_grid_pad.not-loggedin {
		padding-top: 147%;
	}

	.jackpot--hilo {
		margin: 0 auto;
	}
}

@media (min-width: 1600px) {
	.leaderboard_grid_pad.not-loggedin {
		padding-top: 138%;
	}
}

@media (min-width: 1800px) {
	.not-loggedin .medium_grid_pad {
		padding-top: 74.6% !important;
	}
	.leaderboard_grid_pad.not-loggedin {
		padding-top: 112%;
	}
}
@media (max-width: 1024px) {
	.menus {
		overflow: initial;
		width: 100%;
		right: 0;
		display: flex;
		flex-flow: column;
	}
	.logo {
		max-width: initial;
	}
	.logo img {
		max-width: 100%;
		margin-right: initial;
	}

	.burger_menu {
		display: block;
		z-index: 1;
		min-height: 20px;
		position: relative;
	}
	.most_wagered,
	.tables_tab li {
		font-size: 15px;
	}
	.burger_menu span {
		width: 30px;
		height: 3px;
		margin-top: 5px;
		border-radius: 6px;
		display: block;
		background: #607e8b;
	}
	.burger_menu span:first-child {
		margin-top: 0;
	}
	.burger_menu span {
		transition: width 0.5s ease;
	}
	.burger_menu span:nth-child(1) {
		transition: transform 0.5s ease;
	}
	.burger_menu span:nth-child(2) {
		transition: opacity 0.5s ease;
	}
	.burger_menu span:nth-child(3) {
		transition: transform 0.5s ease;
	}

	.player_count {
		font-size: 18px;
		padding-left: 2px;
	}
	.menu_section {
		display: block;
		width: auto;
	}
	.menu_links {
		background-color: #2f3e45;
		text-align: center;
		overflow: auto;
		padding: 65px 5px 0;
		transform: translateY(-100%);
		transition: transform 0.5s;
		min-height: 100vh;
		-webkit-overflow-scrolling: touch;
		flex-direction: column;
	}

	.menu_flag,
	.help_menu {
		background-color: #232c31;
	}

	.question_mark {
		width: 24px;
		height: 24px;
		margin-right: 30px;
		margin-bottom: 0;
	}

	.mob_logout #logoutDropdown {
		display: block;
		position: static;
		color: #647a87;
		font-size: 18px;
		text-transform: uppercase;
		padding: 15px 0 15px;
	}
	.btn {
		font-size: 15px;
	}
	.help_menu {
		margin-left: 0;
	}
	.menu_section,
	.menu_links {
		height: 100%;
		position: absolute;
		width: 100%;
		top: 0;
		left: 0;
	}
	.trade_txt {
		font-size: 17px;
	}
	.col_two_third.big_grids,
	.col_one_third.aff_grid,
	.col_one_third.daily_grid,
	.col_one_third.twitter_grid,
	.col_one_third.crash_grid,
	.col_one_third.roulette_grid,
	.col_one_third.hilo_grid,
	.col_one_third.tradeup_grid,
	.status_grid,
	.col_two_third.graph_grid,
	.last_grid {
		width: 50%;
	}
	.biggest_grids .col_one_third {
		padding: 10px;
		width: 100%;
	}
	.biggest_grids {
		padding-top: 5px;
	}
	.col_two_third.big_grids,
	.col_one_third.twitter_grid {
		float: right;
	}
	.leaderboard_grid_pad {
		padding-top: 240%;
	}
	.player_tables {
		margin-top: 25px;
	}
	.player_tables .player_table_content {
		width: 100%;
		margin-top: 25px;
	}
	.player_tables .player_table_content:first-child {
		margin-top: 0px;
	}
	.most_wagered {
		margin-bottom: 15px;
	}
	.medium_grid_pad {
		padding-top: 75% !important;
	}
	.twitter_grid_pad {
		padding-top: 75%;
	}
	.twitter_grid_pad.not-loggedin {
		padding-top: 101%;
	}
	.col_grids {
		position: relative;
	}
	.col_one_third.twitter_grid {
		position: absolute;
		bottom: 0;
		right: 0;
	}
	.twitter_grid.not-loggedin {
		position: relative;
		bottom: auto;
		right: auto;
	}
	.status_grid_pad {
		padding-top: 80%;
	}
	.graph_grid_pad {
		padding-top: 80%;
	}
	.np_grid {
		width: 100%;
	}
	.user_stats .user_xp {
		padding: 8px 20px;
	}
	.flag_m [class^="icon-"] {
		margin-right: 0;
		font-size: 26px !important;
		color: #10de7c;
	}
	.ReactModal__Content.dialog-modal-content .ui-dialog .dialogText,
	.ReactModal__Content.dialog-modal-content .form-group .lbl_head,
	.ReactModal__Content.dialog-modal-content .form-group .form-control {
		font-size: 17px;
	}
	.ReactModal__Content.dialog-modal-content > div {
		padding: 20px;
	}
	.steamid {
		word-wrap: break-word;
		word-break: break-all;
	}
	.help_menu,
	.settings_menu {
		display: block;
		margin-left: 0;
	}
	.number_mark:before,
	.number_mark:after {
		border-left: 10px solid transparent;
		border-right: 10px solid transparent;
	}
	.ReactModal__Content > div {
		/* max-width: 60%; */
		margin: 0 auto;
		max-height: 600px;
	}

	.daily-rewards-modal-content {
		height: auto;
	}
}

@media (max-width: 1000px) {
	.check_label .c_sel + span {
		display: inline-block;
		padding-top: 15px;
	}
}

@media (max-width: 820px) {
	.ReactModal__Content > div {
		max-width: 100%;
		margin: 0 auto;
	}
	.chatting_chat_msgs {
		height: calc(100% - 32px);
	}
	.game_history {
		padding-top: 20px;
	}
	.right_block {
		margin-top: 20px;
	}
	.question_mark {
		width: 22px;
		height: 22px;
		font-size: 16px;
	}
	.page_header.ptb0 {
		padding: 20px;
	}
	.s_left {
		width: 23.5%;
	}
	.medium_grid_pad {
		padding-top: 83% !important;
	}
	.twitter_grid_pad {
		padding-top: 83%;
	}
	.twitter_grid_pad.not-loggedin {
		padding-top: 106%;
	}
	.leaderboard_grid_pad.not-loggedin {
		padding-top: 212%;
	}
	.leaderboard_grid_pad {
		padding-top: 267%;
	}
	.aff_col .title,
	.status_today .title {
		padding-top: 83%;
	}
	.status_grid_pad,
	.graph_grid_pad {
		padding-top: 88%;
	}
	.col_one_third.status_grid {
		display: none;
	}
	.col_one_third.trade_url_grid,
	.col_two_third.graph_grid {
		width: 100%;
	}
	.profile .col_two_third.graph_grid .graph_grid_pad {
		padding-top: 50%;
	}
	.informations {
		text-align: center;
		padding-top: 0;
		padding-bottom: 25px;
	}
	.information {
		width: 33.33%;
		margin-top: 25px;
	}
	.information:nth-child(4):before {
		display: none;
	}
	.help_link_select {
		display: block;
		margin-top: 0;
	}
	.sidebar_links {
		display: none;
	}
	.faq_description {
		margin-left: 50px;
	}
	.site_content {
		position: static;
		padding-bottom: 0;
	}
}

@media (max-width: 767px) {
	.chat_lang .tab .lan_close {
		top: -35%;
	}
	.jackpot::before,
	.game_history {
		display: none;
	}
	.jackpot:after {
		left: 6px;
		top: 2px;
	}
	.roll .numbers .number {
		width: 75px;
	}
	.messure {
		display: none;
	}
	.player_count {
		font-size: 16px;
	}
	.jackpot .text {
		position: static;
		color: #2c2a23;
		display: inline-block;
		vertical-align: middle;
		font-weight: 400;
		line-height: 1;
	}
	.jackpot .count {
		background: none;
		display: inline-block;
		vertical-align: middle;
		color: #2c2a23;
		font-weight: 500;
		padding: 0 0 0 10px;
		letter-spacing: 0;
	}
	.jackpot {
		min-width: 0;
		padding: 5px 10px 5px 32px;
		border-radius: 6px;
		margin-left: 0;
		float: left;
	}
	.btn.see_results {
		display: block;
		float: right;
		margin-top: 0;
		line-height: 1;
		font-size: 14px;
	}
	.ReactModal__Overlay {
		padding: 30px;
	}
	.betinput {
		width: 90%;
		display: inline-block;
		vertical-align: middle;
	}

	.menu_links {
		padding-top: 60px;
	}
	.player_entry .player_table_head {
		border: none;
		padding-bottom: 0;
	}
	.pl_count .pl_onli {
		padding-left: 5px;
	}

	.page_header.ptb0 {
		padding-top: 0;
	}
	.medium_grid_pad {
		padding-top: 102% !important;
	}
	.twitter_grid_pad {
		padding-top: 102%;
	}
	.pro_desc {
		padding: 0;
	}
	.col_one_third.trade_url_grid {
		width: 50%;
	}
	.profile .col_two_third.graph_grid .graph_grid_pad {
		padding-top: 61%;
	}
	.placeholder_txt {
		font-size: 15px;
	}
	.user_pro_xp,
	.stats_hours {
		width: 100%;
	}
	.stats_hours {
		padding-left: 0;
		margin-top: 20px;
	}
	.chat_input[type="text"] {
		padding-right: 76px;
	}
	.field_groups {
		padding: 20px;
	}
	.leaderboard_grid_pad {
		padding-top: 333%;
	}
	.leaderboard_grid_pad.not-loggedin {
		padding-top: 275%;
	}
}
@media (max-width: 600px) {
	.ReactModal__Content > div {
		max-height: 550px;
	}
	.chatting_chat_msgs {
		height: calc(100% - 59px);
	}
	.chat_lang .tab .lan_close {
		top: -35%;
	}
	.page_header {
		padding: 15px 20px;
	}
	.bet_btn {
		width: 24%;
		margin-top: 1%;
		margin-left: 1%;
	}
	.chat_collapse {
		display: block;
	}
	.text_hold .bet_btn:first-child {
		margin-left: 1%;
	}
	.chat_rooms_head {
		padding: 0px 10px;
	}
	.chat_rooms .chat_langs {
		width: 100%;
	}
	.chat_rooms .chat_langs {
		transform: translateX(100%);
		left: 0;
		z-index: 2;
		padding: 0;
	}
	.chat_rooms .chat_langs ul {
		padding: 20px 45px 30px 30px;
	}
	.chat_rooms.chat_lang_show .chat_langs {
		transform: translateX(0);
	}
	.chat_rooms.chat_lang_show:after {
		display: none;
	}
	.chat_lang_back {
		display: block;
		font-size: 16px;
		line-height: 1;
		background: #3d4f59;
		padding: 10px 15px;
		color: #0ee07b;
	}
	.chat_lang_back .icon {
		font-size: 12px;
		padding-right: 10px;
	}
	.chat_lang_back > * {
		display: inline-block;
		vertical-align: middle;
	}
	.chat_lan {
		font-size: 17px;
	}
	.chat_lang {
		height: 50px;
	}
	.chat_link {
		padding: 15px 0;
	}
	.chat_lang .tab {
		padding: 7px 0;
	}
	.chat_lang .tab > * {
		line-height: 1;
	}
	.ReactModal__Overlay {
		padding: 20px;
	}
	.pl_entry_headin {
		display: none;
	}
	.pl_entry_head {
		color: #ffffff;
		font-weight: 500;
	}
	.pl_count .icon {
		font-size: 18px;
	}
	.check_label:before {
		top: 2px;
	}
	.check_label:after {
		top: 4px;
	}
	.page_header.ptb0 {
		min-height: 0;
		padding-bottom: 15px;
	}
	.game_controls {
		padding: 10px;
		position: static;
	}

	.counting {
		display: none;
	}
	.btns_normal .col_one_fourth {
		width: 50%;
	}
	.btns_clr .col_one_third,
	.btns_normal .col_one_fourth {
		padding: 0 3px;
	}
	.game_controls.mt20 {
		margin-top: 10px;
	}
	.btns_normal .col_one_fourth:nth-child(3),
	.btns_normal .col_one_fourth:nth-child(4) {
		margin-top: 7px;
	}
	.hi-bet .chance,
	.hi-bet .multi {
		top: 10px;
		bottom: auto;
	}
	.s_left {
		width: 100%;
	}
	.s_left {
		padding-right: 0;
	}
	.col_half,
	.col_two_third.big_grids,
	.col_one_third.aff_grid,
	.col_one_third.daily_grid,
	.col_one_third.twitter_grid,
	.col_one_third.crash_grid,
	.col_one_third.roulette_grid,
	.col_one_third.hilo_grid,
	.col_one_third.tradeup_grid,
	.status_grid,
	.col_two_third.graph_grid,
	.last_grid {
		width: 100%;
	}
	.twitter_grid_pad {
		padding-top: 180%;
	}
	.twitter_grid_pad.not-loggedin {
		padding-top: 75%;
	}
	.col_grids {
		padding-bottom: 0%;
	}
	.col_grids.not-loggedin {
		padding-bottom: 0;
	}
	.padd_abs,
	.biggest_grid {
		position: static;
	}
	.twitter_grid_pad .padd_abs {
		position: absolute;
	}
	.medium_grid_pad,
	.profile .col_two_third.graph_grid .graph_grid_pad,
	.leaderboard_grid_pad,
	.leaderboard_grid_pad.not-loggedin {
		padding-top: 0 !important;
	}
	.profile .col_grids {
		padding-bottom: 0;
	}
	.information {
		width: 50%;
	}
	.information:nth-child(4)::before {
		display: block;
	}
	.information:nth-child(3)::before,
	.information:nth-child(5)::before {
		display: none;
	}
	.home .btn,
	.profile .btn {
		width: auto;
	}
	.statistics {
		padding: 15px 10px 20px 10px;
	}
	.faq .search {
		max-width: none;
		width: 100%;
		width: calc(100% + 40px);
		margin-left: -20px;
		border-radius: 6px 6px 0 0;
		box-shadow: none;
	}
	.p30.faq {
		padding-top: 0;
	}
	.left_txt,
	.mailto {
		width: 100%;
		display: block;
	}
	.user_stats .user_pro_xp {
		text-align: center;
	}
	.stats_hours {
		margin-top: 15px;
	}
	.noti_msg {
		font-size: 18px;
	}
	.notification_pop {
		padding: 30px 25px;
	}
	.best_timing {
		display: block;
	}
	.chat_link {
		margin-bottom: -49px;
	}
}

@media (max-width: 420px) {
	.reCaptcha {
		transform: scale(0.77);
		transform-origin: 0 0;
		max-width: 220px;
		display: inline-block;
	}
	.burger_menu span {
		width: 25px;
	}
	.most_wagered,
	.tables_tab li {
		font-size: 14px;
	}
	.btn {
		width: 100%;
		margin-top: 15px;
		padding: 13px 20px;
	}
	.btn.see_results {
		width: 105px;
		padding: 8px;
		min-width: 105px;
	}
	.reset_net_profit {
		padding: 8px;
	}
	.clear_text {
		font-size: 11px;
	}
	.field_group input {
		padding-right: 40px;
	}
	.player_count {
		font-size: 14px;
	}
	.jackpot:after {
		font-size: 18px;
		top: 3px;
	}
	.jackpot {
		padding: 5px 10px 5px 28px;
	}
	.jackpot .text,
	.chat_user_name {
		/* .jackpot .count { */
		font-size: 14px;
	}
	.reset_net_profit {
		padding: 7px;
	}

	.chat_lang_back {
		font-size: 15px;
	}
	.chat_lang_back .icon {
		font-size: 11px;
	}
	.chat_rooms .chat_langs ul {
		padding: 15px 45px 20px 20px;
	}
	.question_mark {
		width: 21px;
		height: 21px;
		font-size: 14px;
	}
	.flag_m > img {
		height: 18px;
	}
	.mob_logout #logoutDropdown {
		font-size: 16px;
	}
	.chat_lan {
		font-size: 15px;
	}
	.helper_text {
		padding-top: 0;
	}
	.entries .col_one_third {
		padding: 0 5px;
	}
	.entries {
		margin: 0 -5px;
	}
	.player_entry .player_table_head {
		margin-left: 0;
		margin-right: 0;
	}
	.pl_count:first-child {
		margin-left: -3px;
	}
	.pl_count .icon {
		font-size: 19px;
	}
	#test {
		background: green !important;
	}
	.pl_entry_head {
		font-size: 14px;
	}
	.xp {
		font-size: 14px;
	}
	.biggest_grid {
		padding: 20px;
	}
	.information .icon {
		font-size: 30px;
		margin-top: 25px;
	}
	.pro_desc {
		margin: 15px auto 0;
		font-size: 15px;
	}
	.p30 {
		padding: 15px;
	}
	.p30.faq {
		padding: 0 10px 15px 10px;
	}
	.faq .search {
		width: calc(100% + 20px);
		margin-left: -10px;
	}
	.faq_single .f_ques,
	.sub_heading {
		font-size: 16px;
	}
	.f_ques:before {
		font-size: 22px;
		transform: none;
		top: -5px;
	}
	.faq_description,
	.editor ul li,
	.editor ol li,
	.description {
		font-size: 14px;
		line-height: 1.5125;
	}
	.faq_description {
		margin-left: 35px;
	}
	.faqs_container h5 {
		margin-top: 5px;
		margin-bottom: 5px;
		font-size: 15px;
	}
	.heading {
		font-size: 16px;
	}
	.heading {
		line-height: 1.4125;
	}
	.description {
		line-height: 1.5125;
	}
	.user_stats_graph {
		padding: 10px;
	}
	.reset_net_profit {
		font-size: 13px;
	}
	.err_msg + p {
		font-size: 18px;
	}
	.tradeup_extended_hold,
	.previous_seed {
		padding: 0 15px;
	}
	.provably_fair_tradeup .btn {
		margin-left: 0;
	}
	.c_sel:after {
		transform: none;
		top: auto;
		bottom: 0;
	}
	.player_tables_content {
		padding: 10px;
	}
	.betinput {
		width: 88%;
	}
}
@media (max-width: 350px) {
	.jackpot:after {
		font-size: 16px;
		left: 3px;
	}
	.jackpot .text, .chat_user_name, /* .jackpot .count {
		font-size: 13px;
	} */
	.jackpot {
		padding: 5px 5px 5px 10px;
	}
	.jackpot .text {
		padding: 0 0 0 10px;
		font-size: 13px;
	}
	.jackpot:after {
		top: 5px;
	}
	.jackpot .count {
		padding: 0 0 0 5px;
	}
	.btn.see_results {
		width: 100px;
		min-width: 100px;
		padding: 8px 0px;
	}
	.table {
		font-size: 13px;
	}
	.player_entry {
		padding: 0px;
	}
	.inline_block {
		display: block;
		margin-top: 10px;
	}
	.inline_block:first-child {
		margin-top: 0;
	}
	.betinput + .col_row {
		margin: 0 -5px;
	}
	.betinput + .col_row .col_half {
		padding: 0 5px;
	}
	.best_timing {
		padding: 20px 10px;
	}
	.btn {
		font-size: 14px;
	}
	.faq_single .f_ques,
	.sub_heading,
	.heading {
		font-size: 15px;
	}
	.sub_heading {
		margin-top: 10px;
	}
	.ReactModal__Content.dialog-modal-content > div {
		padding: 40px 25px;
	}
	.player_tables_content {
		padding: 5px;
	}
}
@media (min-height: 835px) {
	.p2p-info-container {
		height: calc(100% - 147px);
	}
	.p2p-height-limit {
		display: block;
	}
}
@media (max-width: 480px) {
	.logo img {
		margin-right: unset !important;
	}
}
