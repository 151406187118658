.dropdown-wrap {
	&--open {
		background: #2f3d45;
	}
}

.dropdown-icon {
	position: absolute;
	right: 10px;
	font-weight: 700;
	font-size: 16px;
	@include posVertAlign;
}

.inp-wrap--dropdown-multi {
	padding: 5px;
}
