.eg {
	padding: 46px 98px;

	&__arrow {
		width: 26px;
		height: 26px;
		padding: 0;

		&:hover {
			color: white;
			background: transparent;
			filter: unset;
		}

		&--left {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
			position: relative;
			right: -2px;
			border-right: 0px;
		}
		&--right {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}
	}
	&__title {
		font-weight: bold;
		font-size: 24px;
		color: white;
		margin-bottom: 10px;

		&--sm {
			font-size: 16px;
			margin-bottom: 14px;
		}
	}
	&__filters {
		display: flex;
		flex-wrap: wrap;
	}
	&__filters-left {
		display: flex;
	}
	&__game-wrap {
		&--expanded {
			position: fixed;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			z-index: 999999;
			display: flex;
			flex-wrap: wrap;
			flex-direction: column;
		}
	}

	&__filter {
		margin-right: 10px;
		min-height: 39px;
		border-radius: 6px;
		border: 2px solid #2f3d45;
		font-weight: 500;
		height: unset;
		min-height: 39px;
		margin-bottom: 10px;

		.inp-title {
			color: #aebfc7;
		}

		::placeholder {
			color: rgba(174, 191, 199, 0.25);
			font-size: 14px;
		}

		&:last-child {
			margin-right: 0;
		}
	}

	&__search {
		margin-left: auto;
		max-width: 185px;
		border: none;

		.isearch {
			color: rgba(174, 191, 199, 0.5);
		}
	}
	&__providers {
		padding-right: 44px;
	}
	&__back {
		position: absolute;
		left: 0;
	}
	&__iframe-wrap {
		@at-root .eg__game-wrap--expanded & {
			max-height: unset;
			&::after {
				content: unset;
			}
		}
	}
}
